import { useState } from "react";

export type LatLongInputProps = {
  label: string;
  value?: string;
  setValue: (value: string) => void;
};

const LatLongInput = (props: LatLongInputProps) => {
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const regex = /^-?[0-9]{0,3}(\.[0-9]{0,6})?$/;    
    setIsValid(regex.test(inputValue));
    props.setValue(inputValue);
  };

  return (
    <>
      <label className="block text-sm font-medium text-gray-700" >{props.label}</label>
      <input
        type="text"
        value={props.value}
        onChange={handleInputChange}
        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
      />
      {!isValid && (
        <p style={{ color: "red" }}>Please enter a valid {props.label}.</p>
      )}
    </>
  );
};

export default LatLongInput;
