import { useParams } from "react-router-dom";
import Site from "./";

export const SiteView = () => {
    const { siteId } = useParams();

    return (
        <Site siteId={siteId}></Site>
    );
};
