import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvent } from "react-leaflet";
import { Site } from "../../site/Site";
import { fetchWithAuth } from "../../../utils/apiUtils";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { get } from "http";
import { Switch } from "@headlessui/react";
import { useConfig } from "../config-context/ConfigContext";



var operatorIcon = new L.Icon({
    iconUrl: require("../../../img/worker.png"),
    iconSize: [25, 25], // size of the icon
});

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }



const popupContainerStyle = {
    maxWidth: '300px',
    margin: '0',
    padding: '0',

};

const popupTitleStyle = {
    marginTop: '0',
    width: '100%',
    backgroundColor: '#f1f1f1',
    // margin: '10px 0',
    padding: '10px',
    borderRadius: '11px 11px 0 0',
};

const popupInfoStyle = {
    margin: '8px 0',
};

const StyledPopup = styled(Popup)`

  .leaflet-popup-tip-container {
    visibility: hidden;
  }

  .leaflet-popup-content {
    margin: 0;
    padding: 0;
  }
`;

interface DashboardMapProps {
    ids: string[];
}

function SmoothZoomToBounds({ bounds, animate, callback }: any) {
    const map = useMap();
    const previousBoundsRef = useRef(null);

    useEffect(() => {
        const previousBounds = previousBoundsRef.current;

        if (previousBounds && bounds !== previousBounds) {
            map.flyToBounds(bounds, {
                animate: animate || false,
                duration: 2,
                easeLinearity: .99,
            }).on('moveend', () => {
                const zoomLevel = map.getZoom();
                console.log('Current Zoom Level:', zoomLevel);

                // If there's a callback provided, call it
                if (callback) {
                    callback(zoomLevel);
                }
            });
            
            
        }

        previousBoundsRef.current = bounds;
    }, [bounds, animate, map]);

    return null;
}


export default function DashboardMap({ ids }: DashboardMapProps) {
    const navigate = useNavigate();
    const [showMap, setShowMap] = useState(false);
    const [showAllSites, setShowAllSites] = useState(false);
    const [sites, setSites] = useState<Site[]>([]);
    const [bounds, setBounds] = useState<any>([]);
    const [center, setCenter] = useState<any>([35.565, -97.5]);
    const [zoom, setZoom] = useState<any>(6);
    const [mapKey, setMapKey] = useState('');
    const [operators, setOperators] = useState<any[]>([]);
    const config = useConfig();
    const getSiteLabel = config.siteTitle || 'Site';
    const getIconPath = config.iconPath || 'oxxo.png';
    const getIconSize = config.iconSize || [30, 30];

    var windmillIcon = new L.Icon({
        // iconUrl: require("../../../img/oil-rig.png"),
        // iconUrl: require("../../../img/cargo-truck.png"),
        // iconUrl: require("../../../img/oxxo.png"),
        // iconUrl: `http://localhost:3000/public/${getIconPath}`,
        iconUrl: `${process.env.REACT_APP_FIELD_API}/public/${getIconPath}`,
        // @ts-ignore
        iconSize: getIconSize, // size of the icon
        
        // iconSize: [30, 30], // size of the icon
    });

    const getSites = async (operatorIds: string[]) => {
        if (operatorIds.length > 0 && !showAllSites) {
            let sitesUrl = 'sites';
            sitesUrl += `?ids[]=${operatorIds.join('&ids[]=')}`;
            const sitesResults = await fetchWithAuth(sitesUrl, {});
            const sites: Site[] = await sitesResults.json();

            if (sitesResults.ok) {
                return sites;
            }
        } else if (showAllSites) {
            const sitesResults = await fetchWithAuth('sites', {});
            const sites: Site[] = await sitesResults.json();

            if (sitesResults.ok) {
                return sites;
            }
        }
    };

    const boundToOperators = async (operators: any[]) => {
        const bounds = L.latLngBounds(operators.map(operator => [operator.detail.location.coordinates[1], operator.detail.location.coordinates[0]]));
        setAndRenderBounds(bounds);
    }

    const boundToSites = async (sites: Site[]) => {
        const bounds = L.latLngBounds(sites.map(site => [site.location.coordinates[0], site.location.coordinates[1]]));
        setAndRenderBounds(bounds);
    };

    const boundToOKC = async () => {
        const bounds = L.latLngBounds([35.2909, -97.8212], [35.6528, -97.2780]);
        setAndRenderBounds(bounds);
    };

    const getOperators = async () => {
        const contractorsResults = await fetchWithAuth('operators', {});
        const contractors: any[] = await contractorsResults.json();

        if (contractorsResults.ok) {
            // setOperators(contractors);
            return contractors;
        }
    };

    const setAndRenderBounds = (bounds: L.LatLngBounds) => {
        setBounds(bounds);
        // setShowMap(true);
        // setMapKey(ids.join(','));
    }
    
    const callback = (newZoom: number) => {
        console.log('callback');
        setCenter([35.565, -97.5]);
        setZoom(newZoom);
    }

    useEffect(() => {
        // TODO: Determine if this is still needed?
        // window.dispatchEvent(new Event("resize")); // trigger resize event
        setShowMap(true);
        boundToOKC();
        getOperators().then((operators) => {
            if (operators) {
                console.log('operators: ', operators);
                setOperators(operators);
                if (ids.length === 0) {
                    boundToOperators(operators);
                }
            } else {
                setOperators([]);
            }
        });
        getSites(ids).then((sites) => {
            if (sites) {
                console.log('sites: ', sites);
                setSites(sites);
                boundToSites(sites);
            } else {
                setSites([]);
                boundToOKC();
            }
        });
        
    }, [ids]);

    const goToSiteDetail = (siteId: string) => {
        navigate(`/site/${siteId}`);
    };

    return (
        <div className="m-auto align-middle rounded-xl overflow-hidden drop-shadow-xl p-0 m-0 bg-gray-50 mt-8">
            {/* TODO: CHANGE BACK
            {/* <h1 className="text-left text-md font-semibold text-gray-900 text-center my-2">Alerting Sites</h1> */}
            {/* <h1 className="text-left text-md font-semibold text-gray-900 text-center my-2">Alerting {{config?.labels.?siteTitle}}</h1> */}
            {/* @ts-ignore */}
            <div className="flex items-center justify-between px-4 my-1">
                <div className="flex-grow text-center">
                    <h1 className="text-md font-semibold text-gray-900 inline-block">Alerting {getSiteLabel} {showAllSites}</h1>
                </div>
                <Switch.Group>
                    <Switch
                        checked={showAllSites}
                        onChange={(changeVal: boolean) => {
                            console.log('changeVal: ', changeVal);
                            setShowAllSites(changeVal);
                            getSites(ids).then((sites) => {
                                if (sites) {
                                    console.log('sites: ', sites);
                                    setSites(sites);
                                    boundToSites(sites);
                                } else {
                                    setSites([]);
                                    boundToOKC();
                                }
                            });
                        }}
                        className={classNames(
                            showAllSites ? "bg-indigo-500" : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                        )}
                    >
                        <span
                            aria-hidden="true"
                            className={classNames(
                                showAllSites ? "translate-x-5" : "translate-x-0",
                                "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                        />
                    </Switch>
                </Switch.Group>
            </div>



            <div className="bg-gray-50 text-left text-sm font-semibold text-gray-900 rounded-md" style={{ borderTop: '1px solid lightgrey' }}>
                {showMap && (
                    <MapContainer
                        key={mapKey}
                        style={{ height: "600px", width: "400px" }}
                        center={center}
                        zoom={zoom}
                        maxZoom={18}
                        minZoom={0}
                        scrollWheelZoom={true}
                        zoomControl={false}
                        attributionControl={false}
                    >
                        <SmoothZoomToBounds bounds={bounds} animate={true} callback={callback} />
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {sites.length > 0 &&
                            sites.map((site, i) => (
                                <Marker
                                    key={i}
                                    icon={windmillIcon}
                                    position={[
                                        site.location.coordinates[0],
                                        site.location.coordinates[1],
                                    ]}
                                >
                                    <StyledPopup

                                        // className="m-auto align-middle rounded-xl overflow-hidden drop-shadow-xl p-0 m-0 bg-gray-50"
                                        closeButton={true}
                                    >
                                        <div style={popupContainerStyle}>
                                            <h1 style={popupTitleStyle}>{site.detail.wellId} ( <a className="text-blue-500 cursor-pointer hover:underline" onClick={() => goToSiteDetail(site._id)}>View Site</a> )</h1>
                                            <div style={{ padding: '8px', }}>
                                                <p className="bg-white cursor-pointer hover:bg-yellow-100" style={popupInfoStyle}><strong style={{ color: 'black' }}>API Number:</strong> <span style={{ color: 'grey' }}>{site.detail.apiNumber}</span></p>
                                                <p className="bg-white cursor-pointer hover:bg-yellow-100" style={popupInfoStyle}><strong style={{ color: 'black' }}>Description:</strong> <span style={{ color: 'grey' }}>{site.detail.wellDescription}</span></p>
                                                <p className="bg-white cursor-pointer hover:bg-yellow-100" style={popupInfoStyle}><strong style={{ color: 'black' }}>Ranking:</strong> <span style={{ color: 'grey' }}>{site.detail.ranking}</span></p>
                                                <p className="bg-white cursor-pointer hover:bg-yellow-100" style={popupInfoStyle}><strong style={{ color: 'black' }}>Working Interest:</strong> <span style={{ color: 'grey' }}>{site.detail.workingInterest}</span></p>
                                                <p className="bg-white cursor-pointer hover:bg-yellow-100" style={popupInfoStyle}><strong style={{ color: 'black' }}>Notes:</strong> <span style={{ color: 'grey' }}>{site.detail.notes}</span></p>
                                                <p className="bg-white cursor-pointer hover:bg-yellow-100" style={popupInfoStyle}><strong style={{ color: 'black' }}>Site Types:</strong> <span style={{ color: 'grey' }}>{site.detail.siteTypes.join(', ')}</span></p>
                                            </div>
                                        </div>
                                    </StyledPopup>
                                </Marker>
                            ))}

                        {operators.length > 0 &&
                            operators.map((operator, i) => (
                                <Marker
                                    key={i}
                                    icon={operatorIcon}
                                    position={[
                                        operator.detail.location.coordinates[1],
                                        operator.detail.location.coordinates[0],
                                    ]}
                                >
                                </Marker>
                            ))}

                    </MapContainer>
                )}
            </div>
        </div>

    );
}
