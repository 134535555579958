import { ChangeEvent, Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ApiInput from "./ApiInput";
import LatLongInput from "./LatLonInput";
import RankingInput from "./RankingInput";
import WorkingInterestInput from "./WorkingInterestInput";
import WellTypeSelector from "./WellType";
import { Site } from "./Site";
import { fetchWithAuth } from "../../utils/apiUtils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useConfig } from "../common/config-context/ConfigContext";

const defaultSiteType: Site = {
  _id: "",
  companyId: "",
  location: {
    type: "Point",
    coordinates: [0, 0],
  },
  detail: {
    apiNumber: "",
    wellId: "",
    ranking: 0,
    workingInterest: 0,
    siteTypes: [],
    notes: "",
    wellDescription: "",
  },
};

export default function SiteModal(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedSiteId?: string | null;
}) {
  const [site, setSite] = useState<Site>(defaultSiteType);
  const cancelButtonRef = useRef(null);
  const config = useConfig();

  const fetchSite = async () => {
    const siteResults = await fetchWithAuth(`site/${props.selectedSiteId}`, {});
    const fetchedSite = await siteResults.json();

    if (siteResults.ok) {
      setSite(fetchedSite);
    }
  }

  const setSiteDetail = (value: any, field: string) => {
    setSite({
      ...site,
      detail: {
        ...site.detail,
        [field]: value
      }
    });
  }

  const setSiteLon = (value: any) => {
    setSite({
      ...site,
      location: {
        ...site.location,
        coordinates: [site.location.coordinates[0], value]
      }
    });
  }

  const setSiteLat = (value: any) => {
    setSite({
      ...site,
      location: {
        ...site.location,
        coordinates: [value, site.location.coordinates[1]]
      }
    });
  }

  const isUpdate = () => {
    return props.selectedSiteId !== null;
  }

  const saveSite = async () => {
    const url = isUpdate() ? `site/${site._id}` : 'site';

    const detail = {
      wellDescription: getSiteDetail('wellDescription'),
      wellId: getSiteDetail('wellId'),
      ranking: getSiteDetail('ranking'),
      workingInterest: getSiteDetail('workingInterest'),
      notes: getSiteDetail('notes'),
      siteTypes: getSiteDetail('siteTypes'),
    }

    if (!isUpdate()) {
      // @ts-ignore
      detail.apiNumber = site.detail.apiNumber;
    }
    await fetchWithAuth(url, {
      method: isUpdate() ? "PUT" : "POST",
      headers: {
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({
        location: {
          type: "Point",
          coordinates: [getSiteLocation().lat, getSiteLocation().lon],
        },
        detail,
      })
    });

    props.setOpen(false);
  }

  const getSiteDetail = (property: string) => {
    if (site) {
      // @ts-ignore
      return site.detail[property];
    }
    return undefined;
  }

  const getSiteLocation = () => {
    if (site) {
      return {
        lat: site.location.coordinates[0],
        lon: site.location.coordinates[1],
      }
    }
    return {
      lat: undefined,
      lon: undefined
    }
  }

  const getScadaLink = (wellId: string) => {
    // TODO: this could be different depending on the perspective client. Eventually I will need to get this from the Field API
    // return `http://scada.fieldgistix.com/data/perspective/client/FieldgistixDemo/well/${wellId}`;
    const ignitionUrl = config?.ignitionUrl || '';
    return `${ignitionUrl}/${wellId}`;
  };

  useEffect(() => {
    if (props.selectedSiteId) {
      fetchSite();
    } else {
      setSite(defaultSiteType);
    }
  }, [props.selectedSiteId]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="mt-24 relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  <div className="flex justify-between">
                    <h2
                      id="payment-details-heading"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Site Details&nbsp;
                      {
                        <>(
                          <a className="text-blue-500 cursor-pointer hover:underline" href={getScadaLink(site.detail.wellId)} target="_blank">
                            &nbsp;SCADA Interface&nbsp;
                          </a>
                          )</>
                      }
                    </h2>
                    <button
                      type="button"
                      className="flex rounded-md p-1 border-transparent bg-red-600 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={() => { props.setOpen(false) }}
                      ref={cancelButtonRef}
                    >
                      <XMarkIcon className="h-7 w-7" aria-hidden="true" ></XMarkIcon>
                    </button>
                  </div>
                  <p className="mt-1 text-sm text-gray-500">
                    Update your site information. Please note that updating your site
                    can effect call-out decisions.
                  </p>

                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-1">
                      <ApiInput readonly={isUpdate()} apiNumber={getSiteDetail('apiNumber')} setApiNumber={(value: any) => setSiteDetail(value, 'apiNumber')}></ApiInput>
                    </div>

                    <div className="col-span-4 sm:col-span-1">
                      <label
                        htmlFor="well-id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Well ID
                      </label>
                      <input
                        type="text"
                        name="well-id"
                        id="well-id"
                        autoComplete="off"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                        value={site.detail.wellId}
                        onChange={(e) => setSiteDetail(e.target.value, 'wellId')}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Well Description
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="off"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                        value={site.detail.wellDescription}
                        onChange={(e) => setSiteDetail(e.target.value, 'wellDescription')}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-1">
                      <RankingInput ranking={getSiteDetail('ranking')} setRanking={(value: any) => setSiteDetail(value, 'ranking')}></RankingInput>
                    </div>

                    <div className="col-span-4 sm:col-span-1">
                      <WorkingInterestInput workingInterest={getSiteDetail('workingInterest')} setWorkingInterest={(value: any) => setSiteDetail(value, 'workingInterest')} ></WorkingInterestInput>
                    </div>

                    <div className="col-span-4 sm:col-span-1">
                      <LatLongInput label={'Lattitude'} value={getSiteLocation().lat?.toString()} setValue={(value: any) => setSiteLat(value)}></LatLongInput>
                    </div>

                    <div className="col-span-4 sm:col-span-1">
                      <LatLongInput label={'Longitude'} value={getSiteLocation().lon?.toString()} setValue={(value: any) => setSiteLon(value)}></LatLongInput>
                    </div>

                    <div className="col-span-full">
                      <WellTypeSelector selectedSiteTypes={getSiteDetail('siteTypes')} setSelectedSiteTypes={(value: any) => setSiteDetail(value, 'siteTypes')}></WellTypeSelector>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="comment"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Notes
                      </label>
                      <div className="mt-1">
                        <textarea
                          rows={4}
                          name="comment"
                          id="comment"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          // defaultValue={""}
                          value={getSiteDetail('notes')}
                          onChange={(e) => setSiteDetail(e.target.value, 'notes')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    onClick={saveSite}
                  >
                    {site ? 'Update' : 'Save'}
                  </button>
                  {/* <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
