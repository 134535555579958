import { ChangeEvent, useEffect, useState } from "react";
import { fetchWithAuth } from "../../utils/apiUtils";

export type WellTypeSelectorProps = {
    selectedSiteTypes: string[];
    setSelectedSiteTypes: (selectedSiteTypes: string[]) => void;
};

const WellTypeSelector = (props: WellTypeSelectorProps) => {
    const [siteTypes, setSiteTypes] = useState<string[]>([]);

    const fetchData = async () => {
        const siteTypesResults = await fetchWithAuth('sitetypes', {});
        const fetchedSiteTypes = await siteTypesResults.json();
    
        if (siteTypesResults.ok) {
            setSiteTypes(fetchedSiteTypes);
        }
      };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDeleteChip = (wellType: string) => {
        const updatedWellTypes = props.selectedSiteTypes.filter(
            (wt) => wt !== wellType
        );
        props.setSelectedSiteTypes(updatedWellTypes);
    };

    const handleWellTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const wellType = event.target.value;
        if (wellType !== "" && !props.selectedSiteTypes.includes(wellType)) {
            props.setSelectedSiteTypes([...props.selectedSiteTypes, wellType]);
        }
    };

    return (
        <>
            <label htmlFor="wellType" className="block text-sm font-medium text-gray-700">Well Type</label>
            <div className="flex flex-col items-end">
                <select
                    className="mt-1 block px-9 rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm mb-2"
                    onChange={handleWellTypeChange}
                >
                    <option value="">Select a Well Type</option>
                    {siteTypes.map((siteType) => (
                        <option key={siteType} value={siteType}>
                            {siteType}
                        </option>
                    ))}
                </select>
                <div className="w-full h-full border border-gray-300 rounded-md p-5 text-base">
                    {props.selectedSiteTypes.map((wellType) => (
                        <span className={"inline-flex items-center px-3 py-2 rounded-full text-left text-xs font-medium capitalize bg-blue-100 text-blue-800 mr-1 mb-1" }>
                            {wellType}
                            <button className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-.5 px-1 rounded-full" onClick={() => handleDeleteChip(wellType)}>X</button>
                        </span>
                    ))}
                </div>
            </div>
        </>
    );
};

export default WellTypeSelector;
