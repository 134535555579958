import { Weight } from "./Weight";
import { WeightControl } from "./WeightControl";

export default function WeightSetting(props: {
  weight: Weight;
  title: string;
  blurb: string;
  keyToLabelDic: Object;
  setWeight: (weight: Weight) => void;
  onSave: () => Promise<void>;
}) {
  const getDetailByKey = (key: string) => {
    // @ts-ignore
    return props.weight.detail[key];
  };

  const getLabelByKey = (key: string) => {
    // @ts-ignore
    return props.keyToLabelDic[key];
  };

  return (
    <div className="pt-3">
      <div className="hidden sm:block">
        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
          <div className="mt-2 flex flex-col">
            <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
              <div className="px-4 sm:px-6 lg:px-8 bg-white">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto pt-4">
                    <h1 className="text-xl font-semibold text-gray-900">{props.title}</h1>
                    <p className="mt-2 text-sm text-gray-700">{props.blurb}</p>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                      onClick={props.onSave}
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="mt-8 flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                            >
                              Key
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                            >
                              Weight
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                            >
                              Positive Impact
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {Object.keys(props.weight.detail).map((key: string) => (
                            <WeightControl
                              weight={getDetailByKey(key)}
                              onWeightChange={(weight: number) => {
                                props.setWeight({
                                  ...props.weight,
                                  detail: {
                                    ...props.weight.detail,
                                    [key]: {
                                      ...getDetailByKey(key),
                                      weight: weight,
                                    },
                                  },
                                });
                              }}
                              onPositiveImpactChange={(positiveImpact: boolean) => {
                                props.setWeight({
                                  ...props.weight,
                                  detail: {
                                    ...props.weight.detail,
                                    [key]: {
                                      ...getDetailByKey(key),
                                      positiveImpact: positiveImpact,
                                    },
                                  },
                                });
                              }}
                              label={getLabelByKey(key)}
                            ></WeightControl>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
