import { useEffect, useState } from "react";
import { Weight, WeightDetail } from "./Weight";
import WeightSetting from "./WeightSetting";

const wellTitle = "Well Weights";
const wellBlurb =
  "These weights are used to rank wells based on alert type and well ranking.";
const wellKeyToLabelDic = {
  alertType: "Alert Type",
  wellRanking: "Well Ranking",
};

const operatorTitle = "Operator Weights";
const operatorBlurb =
  "These weights are used to rank operators based on skill, rate, and distance to well.";
const operatorKeyToLabelDic = {
  skill: "Skill",
  rate: "Rate",
  distanceToWell: "Distance to Well",
};

export default function WeightPage() {
  const [wellWeight, setWellWeight] = useState<Weight | null>(null);
  const [wellOperatorWeight, setWellOperatorWeight] = useState<Weight | null>(null);

  const fetchData = async () => {
    const weightsResults = await fetch(
      `${process.env.REACT_APP_FIELD_API}/weights/${process.env.REACT_APP_TEMP_COMPANY_ID}`,
      { method: "GET" }
    );
    const fetchedWeights = await weightsResults.json();

    if (weightsResults.ok) {
      setWellWeight(fetchedWeights.find((w: Weight) => w.weightType === "well"));
      setWellOperatorWeight(
        fetchedWeights.find((w: Weight) => w.weightType === "wellOperator")
      );
    }
  };

  const saveWeights = async (weightType: string, detail: WeightDetail) => {
    const body = {
      companyId: process.env.REACT_APP_TEMP_COMPANY_ID,
      weightType,
      detail,
    };

    await fetch(`${process.env.REACT_APP_FIELD_API}/weight`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {wellWeight && (
        <WeightSetting
          key={"well"}
          weight={wellWeight}
          blurb={wellBlurb}
          title={wellTitle}
          setWeight={setWellWeight}
          onSave={() => saveWeights("well", wellWeight.detail)}
          keyToLabelDic={wellKeyToLabelDic}
        ></WeightSetting>
      )}

      {wellOperatorWeight && (
        <WeightSetting
          key={"wellOperator"}
          weight={wellOperatorWeight}
          blurb={operatorBlurb}
          title={operatorTitle}
          setWeight={setWellOperatorWeight}
          onSave={() => saveWeights("wellOperator", wellOperatorWeight.detail)}
          keyToLabelDic={operatorKeyToLabelDic}
        ></WeightSetting>
      )}
    </>
  );
}
