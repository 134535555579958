export type CardStat = {
  name: string;
  icon: any;
  value: string;
};

export type Props = {
  cardData: CardStat[];
  showHeader?: boolean;
};

export default function StatCards(props: Props) {
  return (
    <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
      {props.showHeader ? (
        <h2 className="text-md font-medium leading-6 text-gray-900">Stats</h2>
      ) : null}
      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3">
        {props.cardData.map((card) => (
          <div key={card.name} className="overflow-hidden rounded-lg bg-white shadow">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <card.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="truncate text-xs font-medium text-gray-500">
                      {card.name}
                    </dt>
                    <dd>
                      <div className="text-sm font-medium text-gray-900">
                        {card.value}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
