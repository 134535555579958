import { useEffect, useState } from 'react';
import Text from "../../img/text.png";
import { HoldButton } from '../common/hold-button';
import WaitingScreen from './waiting';
import { fetchWithAuth } from '../../utils/apiUtils';
import QuestionForm from './QuestionForm';
import {
  ChatBubbleOvalLeftIcon,
  ArrowUturnLeftIcon
} from "@heroicons/react/24/outline";
import Chat from './Chat';

export function IndependentOperatorPage() {
  const [job, setJob] = useState<any>(null);
  const [showChat, setShowChat] = useState(false);

  const fetchJob = async () => {
    const jobResults = await fetchWithAuth('job?status=PENDING_OPERATOR_APPROVAL&status=ASSIGNED', {});

    try {
      const fetchedJob = await jobResults.json();

      if (jobResults.ok && fetchedJob) {
        setJob(fetchedJob);
      } else {
        setJob(null);
      }
    }
    catch (e) {
      setJob(null);
    }
  };

  // Check for compatable job every 10 seconds
  useEffect(() => {
    fetchJob();
    const interval = setInterval(() => {
      fetchJob();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const updateJobStatus = async (status: 'ACCEPTED' | 'DECLINED' | 'COMPLETED') => {
    const response = await fetchWithAuth('job', {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({
        status: status,
        jobId: job._id
      })
    });

    if (response.ok) {
      fetchJob();
    }
  };

  const buildRouteUrl = () => {
    if (job.site && job.site.location && job.site.location.coordinates) {
      const lng = job.site.location.coordinates[1];
      const lat = job.site.location.coordinates[0];
      const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
      return url;
    }

    return '';
  };

  const CurrentJobTemplate = () => {
    return (
      <div className="bg-gray-100 max-h-screen flex flex-col">
        <nav className="bg-black w-full mx-0 px-0">
          <div className="flex justify-between items-center">
            <div className="logo p-4">
              <img src={Text} width="150" alt="Your Company" />
            </div>
            {
              job.status === 'ASSIGNED' &&
              <div onClick={() => { setShowChat(true) }} className="flex items-center">
                <div className="relative inline-block text-left">
                  <div className='text-white mr-2'>
                    <ChatBubbleOvalLeftIcon width={30}></ChatBubbleOvalLeftIcon>
                  </div>
                </div>
              </div>
            }
          </div>
        </nav>

        <div className="bg-white rounded-lg shadow-lg p-4 mb-4 mt-4">
          <div className="flex justify-between items-center mb-2">
            <p className={`text-sm font-bold ${job.status === 'ASSIGNED' ? 'text-green-500' : 'text-orange-500'}`}>
              {job.status}
            </p>
            <a href={buildRouteUrl()} target="_blank" rel="noreferrer" className="text-sm text-blue-500 underline">Directions</a>
          </div>

          <h6><b>Site Notes:</b></h6>
          <div className="bg-gray-200 rounded-lg p-2 mb-2">
            <p className="text-sm text-gray-700">{job.site && job.site.detail && job.site.detail.notes || 'No Notes'}</p>
          </div>
        </div>



        <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
          <h2 className="text-lg font-bold mb-2">Alerts</h2>
          <div className="h-80 overflow-y-scroll">
            {job.alerts && job.alerts.map((alert: any) => (
              <div key={alert._id} className="bg-gray-200 rounded-lg p-2 mb-2">
                <b className="text-sm font-bold text-gray-700 mb-1">{alert.detail.alertType} ( {alert.cleared ? 'Cleared' : 'Active'} )</b>
                <p className="text-xs text-gray-500 mb-1">Updated on {alert.updateTimestamp}</p>
                <p className="text-sm text-gray-700"><b>Detail:</b> {alert.detail.alertDetail}</p>
              </div>
            ))}
          </div>
        </div>

        {
          job.status === 'ASSIGNED' && (
            <QuestionForm></QuestionForm>
          )
        }

        <div className="fixed bottom-0 w-full">
          <div className="mx-4 mb-2">
            {
              job.status === 'ASSIGNED' ? (
                <HoldButton title='Complete Job' buttonType='primary' onHold={() => updateJobStatus('COMPLETED')} ></HoldButton>
              ) : (
                <div className='flex'>
                  <div className='w-1/2 mr-1'>
                    <HoldButton title='Deny Job' onHold={() => updateJobStatus('DECLINED')} ></HoldButton>
                  </div>
                  <div className='w-1/2 ml-1'>
                    <HoldButton title='Accept Job' buttonType='primary' onHold={() => updateJobStatus('ACCEPTED')} ></HoldButton>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }

  return job ? (
    showChat ? (
      <>
        <nav className="bg-black w-full mx-0 px-0">
          <div className="flex justify-between items-center">
            <div className="logo p-4">
              <img src={Text} width="150" alt="Your Company" />
            </div>
            <div onClick={() => { setShowChat(false) }} className="flex items-center">
              <div className="relative inline-block text-left">
                <div className='text-white mr-2'>
                  <ArrowUturnLeftIcon width={30}></ArrowUturnLeftIcon>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <Chat selectedJobId={job._id}></Chat>
      </>
    ) : (
      <CurrentJobTemplate></CurrentJobTemplate>
    )
  ) : (
    <WaitingScreen message="Waiting for compatible jobs." subMessage="Please note that this process may take some time. To indicate your availability for new jobs, please keep this webpage open."></WaitingScreen>
  )
}

export default IndependentOperatorPage;
