
import React, { useContext, useState, useEffect, createContext } from 'react';
import { fetchWithAuth } from '../../../utils/apiUtils';

// {
//   "labels": {
//       "siteTitle": "Stores",
//       "alertTitle": "Alert"
//   },
//   "images": {
//       "iconPath": "oxxo.png"
//   }
// }

// const defaultConfig = {
//     labels: {
//         siteTitle: "Site",
//         alertTitle: "Alert",
//     },
//     images: {
//         iconPath: "oxxo.png",
//         iconSize: [30, 30]
//     },
//     urls: {
//         ignitionUrl: null,
//     }
// };

const defaultConfig = {
    siteTitle: "Site",
    alertTitle: "Alert",
    iconPath: "oxxo.png",
    iconSize: [30, 30],
    ignitionUrl: null,
};

// Create a context
const ConfigContext = createContext(defaultConfig);

// Provider component that fetches and provides the global config
export const ConfigProvider = ({ children }: any) => {
    const [config, setConfig] = useState(defaultConfig);

    useEffect(() => {
        // fetchConfig().then(setConfig);
        fetchConfig();
    }, []);

    async function fetchConfig() {
        const settingResults = await fetchWithAuth("setting", {});
        const fetchResults = await settingResults.json();

        if (settingResults.ok) {
          console.log('settingResults', fetchResults)
          setConfig(fetchResults);
        }
        // return {};
    }

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};

// Custom hook to use the config
export const useConfig = () => useContext(ConfigContext);
