import SocketSingleton from "../services/socket.service";

let refreshingAccessToken = false;

export async function fetchWithAuth(path: string, options: any) {
    // Add the access token to the request headers
    const accessToken = localStorage.getItem('accessToken');
    options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_FIELD_API}/${path}`, options);

        if (response.status === 401 && !refreshingAccessToken) {
            refreshingAccessToken = true;
            const refreshToken = localStorage.getItem('refreshToken');
            try {
                const refreshResponse = await fetch(`${process.env.REACT_APP_FIELD_API}/auth/refresh-token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${refreshToken}`,
                    },
                });

                if (!refreshResponse.ok) {
                    throw refreshResponse;
                }

                const { access_token: newAccessToken } = await refreshResponse.json();

                localStorage.setItem('accessToken', newAccessToken);
                
                // Hacky way to reconnect the socket with the new access token
                SocketSingleton.getInstance().reconnect();

                // Retry the original request with the new access token
                const newOptions = {
                    ...options,
                    headers: {
                        ...options.headers,
                        Authorization: `Bearer ${newAccessToken}`,
                    },
                };
                refreshingAccessToken = false;
                return fetch(`${process.env.REACT_APP_FIELD_API}/${path}`, newOptions);
            } catch (error) {
                logout();
            }
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export async function logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    window.location.reload();
}
