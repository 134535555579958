import { useEffect, useState } from "react";
import Table, { Column } from "../common/table";
import { Site } from "./Site";
import SiteModal from "./SiteModal";
import { fetchWithAuth } from "../../utils/apiUtils";
import { insertDashes } from "./ApiInput";

const columns: Column<Site>[] = [
  {
    renderHeader: (i: number) => (
      <th
        key={i}
        className="hide-on-mobile bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        API
      </th>
    ),
    renderRow: (row, _rowI, colI) => (
      <td key={colI} className="hide-on-mobile whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500">
        {row.detail && row.detail.apiNumber ? insertDashes(row.detail.apiNumber) : "N/A"}
      </td>
    ),
  },
  {
    renderHeader: (i: number) => (
      <th
        key={i}
        className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        Well ID
      </th>
    ),
    renderRow: (row, _rowI, colI) => (
      <td key={colI} className="whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500">
        {row.detail && row.detail.wellId ? row.detail.wellId : "N/A"}
      </td>
    ),
  },
  {
    renderHeader: (i: number) => (
      <th
        key={i}
        className="hide-on-mobile bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        Description
      </th>
    ),
    renderRow: (row, _rowI, colI) => (
      <td key={colI} className="hide-on-mobile whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500">
        {row.detail && row.detail.wellDescription ? row.detail.wellDescription : "N/A"}
      </td>
    ),
  },
  {
    renderHeader: (i: number) => (
      <th
        key={i}
        className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        Ranking
      </th>
    ),
    renderRow: (row, _rowI, colI) => (
      <td key={colI} className="whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500">
        {row.detail && row.detail.ranking ? row.detail.ranking : "N/A"}
      </td>
    ),
  },
  {
    renderHeader: (i: number) => (
      <th
        key={i}
        className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        Working Interest
      </th>
    ),
    renderRow: (row, _rowI, colI) => (
      <td key={colI} className="whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500">
        {row.detail && row.detail.workingInterest ? row.detail.workingInterest : "N/A"}
      </td>
    ),
  },
];

interface SitePageProps {
  siteId?: string;
}

export default function SitePage({ siteId }: SitePageProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [rows, setRows] = useState([]);
  const [siteModalOpen, setSiteModalOpen] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);

  const handleRowClick = (siteId: string) => {
    setSelectedSiteId(siteId);
    setSiteModalOpen(true);
  };

  const fetchData = async () => {
    const siteResults = await fetchWithAuth('sites', {});
    const fetchedSites = await siteResults.json();

    if (siteResults.ok) {
      setRows(fetchedSites);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    if (siteId) {
      setSelectedSiteId(siteId);
      setSiteModalOpen(true);
    }
    fetchData();
  }, [siteId]);

  return (
    <>
      <SiteModal open={siteModalOpen} setOpen={async (open: boolean) => {
        setSiteModalOpen(open);
        await fetchData();
      }} selectedSiteId={selectedSiteId}></SiteModal>

      <Table
        columns={columns}
        rows={rows}
        title="Sites"
        showAddButton={!isMobile}
        onAdd={() => {
          setSelectedSiteId(null);
          setSiteModalOpen(true)
        }}
        currentPage={1}
        pageSize={1}
        totalRows={1}
        onRowClick={(site: Site) => handleRowClick(site._id)}
      ></Table>
    </>
  );
}
