import { useState } from 'react';

export default function QuestionForm() {
  // const [, setComments] = useState(null);
  const [addressedIssue, setAddressedIssue] = useState(null);
  const [communicatedWithCustomer, setCommunicatedWithCustomer] = useState(null);
  const [alarmsReset, setAlarmsReset] = useState(null);
  const [furtherActionRequired, setFurtherActionRequired] = useState(null);


  //   const [candidates, setCandidates] = useState(null);
  //   const [offers, setOffers] = useState(null);

  // @ts-ignore
  const handleYesButtonClick = (event, setter) => {
    event.preventDefault();
    setter(true);
  };

  // @ts-ignore
  const handleNoButtonClick = (event, setter) => {
    event.preventDefault();
    setter(false);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
      <h2 className="text-lg font-bold mb-2">Form</h2>
      <div className="h-80 overflow-y-scroll">



        <div className="divide-y divide-gray-200">
          <div className="relative flex items-start pb-4 pt-3.5">
            <div className="min-w-0 flex-1 text-sm leading-6">
              <label htmlFor="comments" className="font-medium text-gray-900">
                Able to fully address the issue?
              </label>
              {/* <p id="comments-description" className="text-gray-500">
                Get notified when someone posts a comment on a posting.
              </p> */}
            </div>
            <div className="mr-3 flex h-6 items-center">
              <button
                onClick={(event) => handleYesButtonClick(event, setAddressedIssue)}
                className={`inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 ${addressedIssue ? 'bg-green-500' : 'bg-white'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                Yes
              </button>
              <button
                onClick={(event) => handleNoButtonClick(event, setAddressedIssue)}
                className={`ml-3 inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 ${addressedIssue == false ? 'bg-red-500' : 'bg-white'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                No
              </button>
            </div>
          </div>
        </div>

        <div className="divide-y divide-gray-200">
          <div className="relative flex items-start pb-4 pt-3.5">
            <div className="min-w-0 flex-1 text-sm leading-6">
              <label htmlFor="comments" className="font-medium text-gray-900">
                Communicated with customer via chat?
              </label>
              {/* <p id="comments-description" className="text-gray-500">
                Get notified when someone posts a comment on a posting.
              </p> */}
            </div>
            <div className="mr-3 flex h-6 items-center">
              <button
                onClick={(event) => handleYesButtonClick(event, setCommunicatedWithCustomer)}
                className={`inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 ${communicatedWithCustomer ? 'bg-green-500' : 'bg-white'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                Yes
              </button>
              <button
                onClick={(event) => handleNoButtonClick(event, setCommunicatedWithCustomer)}
                className={`ml-3 inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 ${communicatedWithCustomer == false ? 'bg-red-500' : 'bg-white'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                No
              </button>
            </div>
          </div>
        </div>


        <div className="divide-y divide-gray-200">
          <div className="relative flex items-start pb-4 pt-3.5">
            <div className="min-w-0 flex-1 text-sm leading-6">
              <label htmlFor="comments" className="font-medium text-gray-900">
                All alarms reset and functioning?
              </label>
              {/* <p id="comments-description" className="text-gray-500">
                Get notified when someone posts a comment on a posting.
              </p> */}
            </div>
            <div className="mr-3 flex h-6 items-center">
              <button
                onClick={(event) => handleYesButtonClick(event, setAlarmsReset)}
                className={`inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 ${alarmsReset ? 'bg-green-500' : 'bg-white'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                Yes
              </button>
              <button
                onClick={(event) => handleNoButtonClick(event, setAlarmsReset)}
                className={`ml-3 inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 ${alarmsReset == false ? 'bg-red-500' : 'bg-white'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                No
              </button>
            </div>
          </div>
        </div>

        <div className="divide-y divide-gray-200">
          <div className="relative flex items-start pb-4 pt-3.5">
            <div className="min-w-0 flex-1 text-sm leading-6">
              <label htmlFor="comments" className="font-medium text-gray-900">
                Further action required?
              </label>
              {/* <p id="comments-description" className="text-gray-500">
                Get notified when someone posts a comment on a posting.
              </p> */}
            </div>
            <div className="mr-3 flex h-6 items-center">
              <button
                onClick={(event) => handleYesButtonClick(event, setFurtherActionRequired)}
                className={`inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 ${furtherActionRequired ? 'bg-green-500' : 'bg-white'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                Yes
              </button>
              <button
                onClick={(event) => handleNoButtonClick(event, setFurtherActionRequired)}
                className={`ml-3 inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 ${furtherActionRequired == false ? 'bg-red-500' : 'bg-white'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                No
              </button>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}