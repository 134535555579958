import { useParams } from "react-router-dom";
import Inbox from "./";

export const InboxView = () => {
    const { conversationId } = useParams();

    return (
        <Inbox jobId={conversationId} />
    );
};
