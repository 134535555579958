import React, { useState } from "react";

export type RankingInputProps = {
    ranking: number;
    setRanking: (ranking: number) => void;
}

const RankingInput = (props: RankingInputProps) => {
    const [isValid, setIsValid] = useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputVal = event.target.value;
        const rankRegex = /^[1-4]$/; // regular expression for rank validation

        setIsValid(rankRegex.test(inputVal));
        props.setRanking(Number(inputVal));
    };

    return (
        <>
            <label
                htmlFor="rank-input"
                className="block text-sm font-medium text-gray-700"
            >
                Ranking
            </label>
            <input
                id="rank-input"
                type="number"
                min="1"
                max="4"
                maxLength={1}
                value={props.ranking}
                onChange={handleChange}
                placeholder="1-4"
                className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
            />
            {!isValid && <div style={{ color: "red" }}>Please enter a valid Ranking.</div>}
        </>
    );
};

export default RankingInput;
