import { useEffect, useState } from "react";
import { CheckCircleIcon, PlusCircleIcon, ExclamationCircleIcon, BellAlertIcon, CalendarDaysIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import Table, { Column } from "../common/table";
import StatCards from "../common/stat-card";
import JobModal from "./JobModal";
import ConfirmOperatorModal from "../common/ConfirmOperatorModal";
import { fetchWithAuth } from "../../utils/apiUtils";
import Map from "../common/map";
import DashboardMap from "../common/map/dashboardMap";
import AnomalyDetector from "./AnomalyDetector";
import jwt_decode from "jwt-decode";
import { useConfig } from "../common/config-context/ConfigContext";

// const rows: any[] = [
//   {
//     status: JobStatus.UNASSIGNED,
//     createTimestamp: new Date(),
//     detail: {
//       wellId: "123",
//       alertId: "123",
//       alertType: AlertType.FACILITY,
//       alertDetail:
//         "prov:default:/tag:Well 2-2-5/Oil Tank 1 Top Level:/alm:High Oil Tank Level Warning",
//     },
//   },
//   {
//     status: JobStatus.ASSIGNED,
//     createTimestamp: new Date(),
//     detail: {
//       wellId: "123",
//       alertId: "123",
//       alertType: AlertType.FACILITY,
//       alertDetail:
//         "prov:default:/tag:Well 2-2-5/Oil Tank 1 Top Level:/alm:High Oil Tank Level Warning",
//     },
//   },
//   {
//     status: JobStatus.COMPLETED,
//     createTimestamp: new Date(),
//     detail: {
//       wellId: "123",
//       alertId: "123",
//       alertType: AlertType.FACILITY,
//       alertDetail:
//         "prov:default:/tag:Well 2-2-5/Oil Tank 1 Top Level:/alm:High Oil Tank Level Warning",
//     },
//   },
// ];

// const defaultCardStats = [
//   { name: "Success Job %", icon: ChartPieIcon, value: "-" },
//   { name: "Averge Completion Time", icon: ClockIcon, value: "-" },
//   { name: "Total Alerts Today", icon: PlusCircleIcon, value: "-" },
// ];

const defaultCardStats = [
  // TODO: CHANGE BACK
  { name: "Stores Monitored", icon: GlobeAltIcon, value: "-" },
  { name: "Daily Alarms", icon: BellAlertIcon, value: "-" },
  { name: "Monthly Dispatches", icon: CalendarDaysIcon, value: "-" },
];
// For the top line metrics, 
// how about something like  
//  (# of sites monitoring)
//  (# of Alarms today)
//  (# of dispatches this month)
// Sites Monitored
// Daily Alarms
// Monthly Dispatches

const statusStyles = {
  COMPLETED: "bg-green-100 text-green-800",
  PENDING: "bg-yellow-100 text-yellow-800",
  PENDING_MANAGER_APPROVAL: "bg-yellow-100 text-yellow-800",
  PENDING_OPERATOR_APPROVAL: "bg-yellow-100 text-yellow-800",
  ASSIGNED: "bg-blue-100 text-blue-800",
  UNASSIGNED: "bg-gray-100 text-red-800",
};

const statusEmoji = {
  COMPLETED: '✅',
  PENDING: '⏳',
  PENDING_MANAGER_APPROVAL: '⏳',
  PENDING_OPERATOR_APPROVAL: '⏳',
  ASSIGNED: '🔵',
  UNASSIGNED: '🔴',
};

// @ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

type Detail = {
  wellId: string;
  alertId: string;
  alertType: AlertType;
  alertDetail: string;
};

export enum JobStatus {
  UNASSIGNED = "UNASSIGNED",
  ASSIGNED = "ASSIGNED",
  PENDING = "PENDING",
  PENDING_MANAGER_APPROVAL = "PENDING_MANAGER_APPROVAL",
  PENDING_OPERATOR_APPROVAL = "PENDING_OPERATOR_APPROVAL",
  COMPLETED = "COMPLETED",
}

export enum AlertType {
  ENVIROMENTAL = "ENVIROMENTAL",
  ESP = "ESP",
  GAS_LIFT = "GAS_LIFT",
  PUMPING_UNIT = "PUMPING_UNIT",
  PLUNGER = "PLUNGER",
  FACILITY = "FACILITY",
  WATER_GATHERING_SYSTEM = "WATER_GATHERING_SYSTEM",
  HYDROCARBON_SYSTEM = "HYDROCARBON_SYSTEM",
  GENERAL = "GENERAL",
  IE = "I/E",
  MAINTENANCE = "MAINTENANCE",
}

export type Job = {
  _id: string;
  companyId: string;
  wellId: string;
  siteId: string;
  userAssigned: string;
  status: JobStatus;
  totalDenials: number;
  startTimestamp: Date;
  endTimestamp: Date;
  createTimestamp: string;
  updateTimestamp: string;
  detail: Detail;
};

const arraysEqual = (a: any[], b: any[]) => {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export default function DashboardPage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [jobModalOpen, setJobModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<string>();

  const [rows, setRows] = useState<Job[]>([]);
  const [cardStats, setCardStats] = useState(defaultCardStats);
  const [activeSiteIds, setActiveSiteIds] = useState<string[]>([]);

  const config = useConfig();
  const getSiteLabel = config?.siteTitle || 'Site';


  const columns: Column<Job>[] = [
    {
      renderHeader: (i: number) => (
        <th
          key={i}
          className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
          scope="col"
        >
          {/* TODO: CHANGE BACK */}
          {/* Well ID */}
          {getSiteLabel} ID
        </th>
      ),
      renderRow: (row, _rowI, colI) => (
        <td key={colI} className="whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500">
          {/* <span className="font-medium text-gray-900">{transaction.amount}</span> */}
          {/* @ts-ignore */}
          {/* TODO: CHANGE BACK */}
          {row.wellId}
          {/* {90000 + _rowI + 1} */}
        </td>
      ),
    },
    {
      renderHeader: (i: number) => (
        <th
          key={i}
          className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
          scope="col"
        >
          Status
        </th>
      ),
      renderRow: (row, _rowI, colI) => {
        const isMobile = window.innerWidth <= 768;
  
        return (
          <td key={colI} className="whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500 md:block">
            {isMobile ? (
              <span className="ml-2 text-xs text-gray-500">
                {statusEmoji[row.status]}
              </span>
            ) : <span
              className={classNames(
                statusStyles[row.status],
                "inline-flex items-center px-2.5 py-0.5 rounded-full text-left text-xs font-medium capitalize"
              )}
            >
              {row.status}
            </span>}
          </td>
        )
      },
    },
    {
      renderHeader: (i: number) => (
        <th
          key={i}
          className="hide-on-mobile bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
          scope="col"
        >
          Num Of Alerts
        </th>
      ),
      renderRow: (row, _rowI, colI) => (
        <td key={colI} className="hide-on-mobile whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500">
          {/* <span className="font-medium text-gray-900">{transaction.amount}</span> */}
          {/* @ts-ignore */}
          {row.alertIds && row.alertIds.length}
        </td>
      ),
    },
    {
      renderHeader: (i: number) => (
        <th
          key={i}
          className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
          scope="col"
        >
          Alerted At
        </th>
      ),
      renderRow: (row, _rowI, colI) => {
        const isMobile = window.innerWidth <= 768;
  
        return (
          <td
            key={colI}
            className="whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500"
          >
            <time dateTime={row.createTimestamp}>
              {isMobile ? (
                DateTime.fromISO(row.createTimestamp).toFormat("ccc t")
              ) : (
                DateTime.fromISO(row.createTimestamp).toFormat("ccc LLL dd, yyyy 'at' t")
              )}
            </time>
          </td>
        )
      },
    },
  ];



  const fetchData = async (isMounted = true) => {
    const jobResults = await fetchWithAuth("jobs", {});
    const statsResults = await fetchWithAuth("stats", {});

    const fetchedJobs: Job[] = await jobResults.json();
    const fetchedStats = await statsResults.json();

    if (jobResults.ok) {
      setRows(fetchedJobs);

      const newActiveSiteIds = fetchedJobs.filter((job) => job.siteId !== undefined && (
        job.status === JobStatus.PENDING_MANAGER_APPROVAL ||
        job.status === JobStatus.PENDING_OPERATOR_APPROVAL ||
        job.status === JobStatus.UNASSIGNED ||
        job.status === JobStatus.ASSIGNED
      )).map((job) => job.siteId);

      if (!arraysEqual(activeSiteIds, newActiveSiteIds)) {
        console.log(activeSiteIds, newActiveSiteIds)
        setActiveSiteIds(newActiveSiteIds);
      }
    }
    if (statsResults.ok) {
      const newCardStats = defaultCardStats.map((card) => {
        // TODO: CHANGE BACK
        // if (card.name === "Sites Monitored") {
        if (card.name === "Stores Monitored") {
          return {
            ...card,
            name: getSiteLabel + " Monitored",
            value: fetchedStats.sitesMonitoring,
          };
        } else if (card.name === "Daily Alarms") {
          return {
            ...card,
            value: fetchedStats.alertsToday,
          };
        } else if (card.name === "Monthly Dispatches") {
          return {
            ...card,
            value: fetchedStats.dispatchesThisMonth,
          };
        }
      });
      // @ts-ignore
      setCardStats(newCardStats);
    }
  };

  const hackyImplementDemoDeletes = () => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      const decoded: any = jwt_decode(token || '');
      if (decoded?.email === 'ctrammell@fieldgistix.com' && columns.length === 4) {
        columns.push({
          renderHeader: (i: number) => (
            <th
              key={i}
              className="hide-on-mobile bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
              scope="col"
            >
              {/* Delete */}
            </th>
          ),
          renderRow: (row, _rowI, colI) => (
            <td onClick={(e) => e.preventDefault()} key={colI} className="hide-on-mobile whitespace-nowrap px-6 py-2 text-left text-xs text-red-500 ">
              <button onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await fetchWithAuth(`job/${row._id}`, {
                  method: 'DELETE',
                });
                fetchData();
              }}>Delete</button>
            </td>
          ),
        });
      }
    }
  }

  const pollData = (): NodeJS.Timer => {
    return setInterval(() => {
      console.log('polling data')
      fetchData();
    }, 60 * 1000);
  };

  useEffect(() => {
    // let isMounted = true;
    hackyImplementDemoDeletes();
    fetchData();
    const timer = pollData();

    return () => {
      // isMounted = false;
      clearInterval(timer);
    }
  }, [activeSiteIds]);


  return (
    <>
      {selectedJobId && jobModalOpen && (
        <JobModal
          jobId={selectedJobId}
          open={jobModalOpen}
          setOpen={setJobModalOpen}
        ></JobModal>
      )}
      <div className="pt-4">
        <StatCards cardData={cardStats} />
      </div>
      <div className="flex flex-col md:flex-row">
        {
           !isMobile ? (
            <div className="hide-on-mobile pl-8 pr-4">
              <DashboardMap ids={activeSiteIds}></DashboardMap>
            </div>
          ) : null
        }
        
        <div className="pl-4 pr-4 flex-grow">
          <Table
            title="Job Activity"
            columns={columns}
            rows={rows}
            currentPage={1}
            pageSize={1}
            totalRows={1}
            onRowClick={(job: Job) => {
              setSelectedJobId(job._id);
              setJobModalOpen(true);
            }}
          ></Table>
          <div className="pt-4 hide-on-mobile">
            <AnomalyDetector></AnomalyDetector>
          </div>
        </div>
      </div>
    </>
  );
}
