import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { JobWithAlerts } from "../../models/JobWithAlerts";
import Table, { Column } from "../common/table";
import { Alert, AlertDetail, AlertStatus } from "../../models/Alert";
import { DateTime } from "luxon";
import { fetchWithAuth } from "../../utils/apiUtils";
import { useNavigate } from "react-router-dom";
import { JobStatus } from ".";
import { useConfig } from "../common/config-context/ConfigContext";

const columns: Column<Alert>[] = [
  {
    renderHeader: (i: number) => (
      <th
        key={i}
        className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        Detail
      </th>
    ),
    renderRow: (row, _rowI, colI) => (
      <td key={colI} className="w-1/2 max-w-0 whitespace-nowrap px-6 py-2 text-xs text-gray-900">
        <div className="flex">
          <p className="truncate text-gray-500 group-hover:text-gray-900" title={row.detail.alertDetail}>
            {row.detail.alertDetail}
          </p>
        </div>
      </td>
    ),
  },
  {
    renderHeader: (i: number) => (
      <th
        key={i}
        className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        Alert Type
      </th>
    ),
    renderRow: (row, _rowI, colI) => (
      <td
        key={colI}
        className="w-1/4 whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500"
      >
        {row.detail.alertType}
      </td>
    ),
  },
  {
    renderHeader: (i: number) => (
      <th
        key={i}
        className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        Cleared
      </th>
    ),
    renderRow: (row, _rowI, colI) => (
      <td
        key={colI}
        className="w-1/4 whitespace-nowrap px-6 py-2 text-left text-xs text-gray-500"
      >
        {/* @ts-ignore */}
        {row.cleared ? "Yes" : "No"}
      </td>
    ),
  },
];

export default function JobModal(props: {
  jobId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const navigate = useNavigate();
  const [job, setJob] = useState<JobWithAlerts>();
  const cancelButtonRef = useRef(null);
  const config = useConfig();
  const getSiteLabel = config?.siteTitle || 'Site';

  const fetchJobWithAlerts = async () => {
    const jobResults = await fetchWithAuth(`job/${props.jobId}`, {});
    const fetchedJob = await jobResults.json();

    if (jobResults.ok) {
      setJob(fetchedJob);
    }
  };

  useEffect(() => {
    fetchJobWithAlerts();
  }, []);

  const goToJobConversation = () => {
    props.setOpen(false);
    navigate(`/inbox/${props.jobId}`);
  };

  const goToSiteDetail = (siteId: string) => {
    console.log("siteId", siteId);
    navigate(`/site/${siteId}`);
  };

  const getScadaLink = (wellId: string) => {
    // TODO: this could be different depending on the perspective client. Eventually I will need to get this from the Field API
    // return `http://scada.fieldgistix.com/data/perspective/client/FieldgistixDemo/well/${wellId}`;
    const ignitionUrl = config?.ignitionUrl || '';
    return `${ignitionUrl}/${wellId}`;
  };


  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  <div>
                    <h2
                      id="payment-details-heading"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Job Details&nbsp;
                      {
                        job && job.status === JobStatus.ASSIGNED &&
                        <>(
                          <a className="text-blue-500 cursor-pointer hover:underline" onClick={goToJobConversation}>
                            &nbsp;View Conversation&nbsp;
                          </a>
                          )</>
                      }
                    </h2>
                    {/* <p className="mt-1 text-sm text-gray-500">
                      Update your site information. Please note that updating your site
                      can effect call-out decisions.
                    </p> */}
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-6 gap-6">
                  <div className="col-span-4 sm:col-span-2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {getSiteLabel} ID
                    </label>

                    <div onClick={() => goToSiteDetail(job?.siteId || "")}>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="cc-given-name"
                        disabled
                        style={{ pointerEvents: 'none' }}
                        value={job?.wellId || ""}
                        className="text-blue-700 cursor-pointer underline mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                        title="Click to view site"
                      />
                    </div>
                  </div>

                  <div className="col-span-4 sm:col-span-2">
                    <label
                      htmlFor="expiration-date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Status
                    </label>
                    <input
                      type="text"
                      name="expiration-date"
                      id="expiration-date"
                      disabled
                      value={job?.status || ""}
                      className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-4 sm:col-span-2">
                    <label
                      htmlFor="security-code"
                      className="flex items-center text-sm font-medium text-gray-700"
                    >
                      <span>Alerted At</span>
                    </label>

                    <input
                      type="text"
                      name="security-code"
                      id="security-code"
                      disabled
                      value={job?.createTimestamp ? DateTime.fromISO(job.createTimestamp.toString()).toFormat("ccc LLL dd, yyyy 'at' t") : ""}
                      className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="mt-6">
                  <Table
                    columns={columns}
                    rows={job?.alerts || []}
                    title={<>Alerts&nbsp;
                      {
                        job?.wellId &&
                        <>
                          (
                          <a className="text-blue-500 cursor-pointer hover:underline" href={getScadaLink(job?.wellId)} target="_blank">
                            &nbsp;SCADA Interface&nbsp;
                          </a>
                          )
                        </>
                      }
                    </>}
                    onNextPage={() => { }}
                    onPreviousPage={() => { }}
                    currentPage={1}
                    pageSize={1}
                    totalRows={1}
                    removePadding
                  ></Table>
                </div>

                {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3"> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
