import { useEffect, useState } from "react";
import { Alert } from "../../models/Alert";
import { fetchWithAuth } from "../../utils/apiUtils";
import JSON5 from 'json5'
const actionGpt = "SHOULD_BE_SET_IN_ENV";

type Anomoaly = {
    detected: true;
    alertId: string;
    siteId: string;
    description: string; // Human readable description of the anomaly, e.g. "The alert has not been cleared in 24 hours"
    severity: 'Low' | 'Medium' | 'High';
} | {
    detected: false;
}

const mockAnomaly: Anomoaly = {
    detected: true,
    alertId: '123',
    siteId: '123',
    description: 'The alert has not been cleared in 24 hours',
    severity: 'High',
}

export default function AnomalyDetector() {
    const [alerts, setAlerts] = useState<Alert[]>();
    const [anomaly, setAnomaly] = useState<Anomoaly>();
    const shouldMockAnomaly = true;

    const getAlerts = async () => {
        if (shouldMockAnomaly) {
            setAlerts([]);
            setAnomaly(mockAnomaly);
            return;
        }
        const alertResults = await fetchWithAuth('alerts', {});
        const fetchedAlerts = await alertResults.json();

        if (alertResults.ok) {
            setAlerts(fetchedAlerts);
            checkAnomaly(fetchedAlerts);
        }
    }

    const checkAnomaly = async (foundAlerts: Alert[]) => {
        if (!foundAlerts || foundAlerts.length === 0) {
            setAnomaly({
                detected: false
            });
            return;
        }

        const prompt = `
            The following alerts have been detected: ${JSON.stringify(foundAlerts)}

            Your Job is ONLY to respond with a valid JSON structure that EXACTLY matches the schema below. I need you to look at the provided Alerts and determine if there are any anomalies in them.

            Respond with the following JSON structure:
            {
                detected: true;
                alertId: string;
                siteId: string;
                description: string; // Human readable description of the anomaly, e.g. "The alert has not been cleared in 24 hours"
                severity: 'Low' | 'Medium' | 'High';
            } | {
                detected: false;
            }
        `;

        const anomalyResults = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${actionGpt}`
            },
            body: JSON.stringify({
                model: "gpt-3.5-turbo",
                messages: [{ role: "user", content: prompt }],
                temperature: 0.7,
            })
        });
        const fetchedAnomaly = await anomalyResults.json();

        if (anomalyResults.ok) {
            const anomaly = fetchedAnomaly.choices[0].message.content.trim();
            const anomalyObj = JSON5.parse(anomaly);
            setAnomaly(anomalyObj);
        }
    }

    useEffect(() => {
        // getAlerts();
    }, []);

    return (
        <div className="m-auto align-middle rounded-xl  drop-shadow-xl p-0 my-0 bg-gray-50 mt-8 mx-9">
            <h1 className="text-left font-semibold text-gray-900 text-center my-2">AI Detected Alert Anomalies ( Beta )</h1>
            <div className="text-left text-gray-900" style={{ borderTop: '1px solid lightgrey' }}>
                {anomaly && anomaly.detected ? (
                    <div className="p-4  bg-white">
                        <h2 className="text-gray-900 my-2 ml-6">Anomaly Detected ( {anomaly.severity} )</h2>
                        <ul className="text-gray-900 my-2 ml-6">
                            <li className="text-md text-gray-900 my-2 ml-6" style={{ listStyleType: "circle" }}>"<i>{anomaly.description}</i>"</li>
                        </ul>
                        {/* <p className="text-left text-md font-semibold text-red-500 text-center my-2">Attention: This anomaly was detected via artificial intelligence and may be a false positive. Please review the alert and take appropriate action.</p> */}
                        <p className="text-left text-xs font-semibold text-red-500 text-center my-2">Attention: AI Detected Potential Anomaly! Verify Before Acting.</p>
                    </div>
                ) : (
                    <div className="p-4">
                        <h2 className="text-left text-md font-semibold text-gray-900 text-center my-2">No Anomaly Detected</h2>
                    </div>
                )}
            </div>
        </div>
    );
}