import { Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../img/logo.png";
import Text from "../../img/text.png";
import { fetchWithAuth, logout } from "../../utils/apiUtils";
import { JobNotification } from "./JobNotification";
import ConfirmOperatorModal from "../common/ConfirmOperatorModal";
import { useConfig } from "../common/config-context/ConfigContext";
import { get } from "http";

const navigation = [
  { name: "Dashboard", href: "/", current: true },
  { name: "Inbox", href: "/inbox", current: true },
  // { name: "Int. Operators", href: "/internal-operators", current: true },
  // { name: "Operators", href: "operator", current: false },
  // { name: "Jobs", href: "job", current: false },
  // TODO: CHANGE BACK
  { name: "Sites", href: "site", current: false },
  // { name: "Sites", href: "site", current: false },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [navigations, setNavigations] = React.useState(navigation);
  const [notifications, setNotifications] = React.useState<JobNotification[]>([]);
  const [selectedNotification, setSelectedNotification] = React.useState<JobNotification | null>(null);
  const [initials, setInitials] = React.useState<string>("...");
  const config = useConfig();
  const getSiteLabel = config?.siteTitle || 'Sites';

  

  const handleNavigationChange = (selectedName: string) => {
    const updatedNavigation = navigation.map((item) => {
      if (item.name === selectedName) {
        item.current = true;
      } else {
        item.current = false;
      }
      return item;
    });

    setNavigations(updatedNavigation);
  };

  const fetchNotifications = async () => {
    const notificationsResults = await fetchWithAuth(`notifications`, {});
    const notifications = await notificationsResults.json();

    if (notificationsResults.ok) {
      setNotifications(notifications);
    }
  };

  const chipValue = () => {
    if (notifications.length > 0) {
      if (notifications.length > 99) {
        return "99+";
      }
      return notifications.length;
    }
    return 0;
  }

  const getSelectedJobId = () => {
    if (selectedNotification) {
      const selectedJobIdasd = selectedNotification.jobId;

      return selectedJobIdasd;
    }
    return null;
  }

  useEffect(() => {
    console.log("SETTING NAVIGATION");
    setNavigations([
      { name: "Dashboard", href: "/", current: true },
      { name: "Inbox", href: "/inbox", current: true },
      { name: getSiteLabel, href: "site", current: false },
    ]);

    const accessToken = localStorage.getItem('accessToken');
    const decodedToken = accessToken ? JSON.parse(atob(accessToken.split('.')[1])) : null;

    if (decodedToken) {
      setInitials(decodedToken.firstName.charAt(0) + decodedToken.lastName.charAt(0));
    }

    fetchNotifications();
    const interval = setInterval(() => {
      fetchNotifications();
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Disclosure as="nav" className="bg-black">
        {/* bg-gray-800 */}
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-5 w-auto lg:hidden"
                      src={Text}
                      alt="Your Company"
                    />
                    <img
                      className="hidden h-5 w-auto lg:block"
                      src={Text}
                      alt="Your Company"
                    />
                    {/* <img
                    className="block h-5 w-auto lg:hidden"
                    src={Logo}
                    alt="Your Company"
                  />
                  <img
                    className="hidden h-5 w-auto lg:block"
                    src={Logo}
                    alt="Your Company"
                  /> */}
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigations.map((item) => (
                        <NavLink
                          to={item.href}
                          className={({ isActive, isPending }) =>
                            classNames(
                              isActive
                                ? "bg-gray-800 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "px-3 py-2 rounded-md text-sm font-medium"
                            )
                          }
                        // className={classNames(
                        //   item.current
                        //     ? "bg-gray-900 text-white"
                        //     : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        //   "px-3 py-2 rounded-md text-xs font-medium"
                        // )}
                        // aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </NavLink>

                        // <a
                        //   key={item.name}
                        //   onClick={() => handleNavigationChange(item.name)}
                        //   href={item.href}
                        // className={classNames(
                        //   item.current
                        //     ? "bg-gray-900 text-white"
                        //     : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        //   "px-3 py-2 rounded-md text-xs font-medium"
                        // )}
                        // aria-current={item.current ? "page" : undefined}
                        // >
                        //   {item.name}
                        // </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* <button
                  type="button"
                  className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                  <Menu as="div" className="relative ml-3">
                    <div>
                      {/* <Menu.Button className="flex rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </Menu.Button> */}
                      <Menu.Button className="flex rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                        {/* <span className="absolute top-0 right-5 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded-full">{1}</span> */}
                        <span className="absolute top-0 -right-2 -mt-3 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded-full">{chipValue()}</span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                        {
                          notifications.length === 0 && (
                            <Menu.Item>
                              {({ active }) => (
                                <div className="bg-gray-100 border border-gray-300 text-gray-700 px-4 py-3 rounded relative" role="alert">
                                  <span className="block sm:inline text-black">No Notifications</span>
                                </div>
                              )}
                            </Menu.Item>
                          )
                        }

                        {notifications.map((notification) => (
                          <Menu.Item>
                            {({ active }) => (
                              // bg-yellow-100 border border-yellow-300 text-yellow-700
                              <div onClick={() => {setSelectedNotification(notification)}} className="px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline text-black">{notification.title}</span>
                                <span className="absolute top-0 bottom-0 right-0 px-4 py-3 text-black">
                                  <ExclamationTriangleIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                                </span>
                                <p className="text-sm text-black">{notification.description}</p>
                              </div>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        {/* <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}
                        <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                          <span className="text-lg font-medium leading-none text-white">
                            {initials}
                          </span>
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {/* <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Your Profile
                            </a>
                          )}
                        </Menu.Item> */}
                        <Menu.Item>
                          {({ active }) => (
                            // <a
                            //   href="#"
                            //   className={classNames(
                            //     active ? "bg-gray-100" : "",
                            //     "block px-4 py-2 text-sm text-gray-700"
                            //   )}
                            // >
                            //   Settings
                            // </a>
                            <Link
                              to={"setting/company"}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Settings
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/login"
                              onClick={logout}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <ConfirmOperatorModal jobId={getSelectedJobId()} onClose={() => { setSelectedNotification(null) }} ></ConfirmOperatorModal>
    </>
  );
}
