import { useEffect, useRef, useState } from "react";
import { fetchWithAuth } from "../../utils/apiUtils";
import SocketSingleton from "../../services/socket.service";

const socket = SocketSingleton.getInstance();

interface ConversationProps {
  selectedJobId: string;
  conversationsExist: boolean;
};

const Conversation = ({ selectedJobId, conversationsExist }: ConversationProps) => {
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<any>([]);
  const [newMessage, setNewMessage] = useState("");

  const handleSend = (event: any) => {
    event.preventDefault();
    if (newMessage) {
      setMessages((messages: any) => [...messages, {
        jobId: selectedJobId,
        text: newMessage,
        from: "MANAGER"
      }]);

      sendMessage(selectedJobId, newMessage);
      scrollToBottom();
      setNewMessage("");
    }
  };

  const fetchConversation = async (jobId: string) => {
    const conversationResults = await fetchWithAuth(`conversation/${jobId}`, {});

    const fetchedConversation = await conversationResults.json();

    if (conversationResults.ok) {
      setMessages(fetchedConversation);
      scrollToBottom();
    }
  };

  const sendMessage = async (jobId: string, text: string) => {
    console.log("sendMessage", jobId, text)
    socket.emit("sendMessage", {
      jobId: jobId,
      text: text,
    });
  };

  useEffect(() => {
    socket.on("sendMessage", (event) => {
      setMessages((messages: any) => [...messages, event]);
      scrollToBottom();
    });

    return () => {
      socket.off("sendMessage");
    };
  }, []);


  const scrollToBottom = () => {
    setTimeout(() => {
      // @ts-ignore
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }, 5);
  };

  useEffect(() => {
    if (selectedJobId) {
      fetchConversation(selectedJobId);
    }
  }, [selectedJobId]);

  const NoConversation = () => (
    <div className="flex-grow flex items-center justify-center text-2xl text-gray-500">
      Select a Conversation
    </div>
  );

  return (
    !selectedJobId ? (
      <NoConversation />
    ) : (
      <div className={`flex-grow h-screen mt-[-64px] bg-gray-100 flex flex-col`}>
        <div ref={messageContainerRef} className="flex-grow overflow-y-auto p-4 mt-20">
          {messages.map((message: any, index: number) => (
            <div
              key={index}
              className={`${message.from === "MANAGER" ? "text-right" : "text-left"} mb-2`}
            >
              <div className={`inline-block ${message.from === "MANAGER" ? "bg-blue-500" : "bg-green-500"} text-white py-1 px-2 rounded-lg`}>
                {message.text}
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between p-4">
          <form onSubmit={handleSend} className="flex-grow">
            <input
              type="text"
              placeholder="Type your message here"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-4 py-2"
              value={newMessage}
              onChange={(event) => setNewMessage(event.target.value)}
            />
          </form>
          <button
            type="submit"
            onClick={handleSend}
            className="ml-2 bg-blue-500 text-white py-2 px-4 rounded-lg"
          >
            Send
          </button>
        </div>
      </div>
    )
  );
};

export default Conversation;
