import { useState } from "react";

export type WorkingInterestInputProps = {
    workingInterest: number;
    setWorkingInterest: (value: number) => void;
};

const WorkingInterestInput = (props: WorkingInterestInputProps) => {
    const [isValid, setIsValid] = useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(0(?:\.\d{1,3})?|1(?:\.0{1,3})?)$/; // regex pattern to match numbers between 0 and 1 with 3 decimal places

        const validInput = regex.test(input);
        setIsValid(validInput);
        props.setWorkingInterest(Number(input));
    };


    return (
        <>
            <label htmlFor="working-interest"
                className="block text-sm font-medium text-gray-700"
            >Working Interest:</label>
            <input
                id="working-interest"
                type="number"
                min="0"
                max="1"
                step="0.001"
                value={props.workingInterest}
                onChange={handleChange}
                maxLength={5} // limit input to 5 characters (including decimal point)
                className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
            />
            {!isValid && <div style={{ color: "red" }}>Please enter a valid Working Interest.</div>}
        </>
    );
};

export default WorkingInterestInput;
