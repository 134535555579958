import Sidebar from "./SideBar";
import Conversation from "./Conversation";
import { useEffect, useState } from "react";

interface InboxProps {
  jobId?: string;
};

export default function Inbox({ jobId }: InboxProps) {
  const [selectedConversation, setSelectedConversation] = useState<any>(null);
  const [conversationsExist, setConversationsExist] = useState<boolean>(false);
 
  useEffect(() => {
    if (jobId) {
      setSelectedConversation(jobId);
      setConversationsExist(true);
    }
  }, [jobId]);

  return (
    <div className="bg-gray-100 flex" style={{height: 'calc(100vh - 64px)'}}>
      <Sidebar className="hide-on-mobile" selectConversation={setSelectedConversation} conversationsExist={conversationsExist} setConversationsExist={setConversationsExist} />
      <Conversation selectedJobId={selectedConversation} conversationsExist={conversationsExist} />
    </div>
  );
}
