import { useState, useRef } from 'react';

type HoldButtonProps = {
    onHold: () => void;
    title: string;
    buttonType?: 'primary' | 'warning';
};

export function HoldButton(props: HoldButtonProps) {
    const [isHeld, setIsHeld] = useState(false);
    const [progress, setProgress] = useState(0);
    const intervalRef = useRef<any>(null);

    function handleMouseDown(e: any) {
        e.preventDefault();
        setIsHeld(true);
        intervalRef.current = setInterval(() => {
            setProgress(prevProgress => {
                const nextProgress = prevProgress + 1;
                if (nextProgress >= 100) {
                    clearInterval(intervalRef.current);
                    props.onHold();
                    reset();
                }
                return nextProgress;
            });
        }, 50);
    }

    function reset() {
        setIsHeld(false);
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        setProgress(0);
    }

    const loadingBackground = props.buttonType === 'primary' ? `linear-gradient(to right, #34D399 ${progress}%, #E5E7EB 0%)` : `linear-gradient(to right, #FF0000 ${progress}%, #E5E7EB 0%)`;
    const backgroundColor = props.buttonType === 'primary' ? 'bg-green-500' : 'bg-red-500';

    return (
        <>
        <button
            className={`${backgroundColor} text-black rounded-lg w-full py-2 mb-4 ${isHeld ? 'bg-gray-300' : ''}`}
            style={ progress >= 1 ? 
                { background: loadingBackground, touchAction: 'none', userSelect: 'none', WebkitTouchCallout: 'none', WebkitUserModify: 'read-only', WebkitUserSelect: 'none', KhtmlUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' } : { touchAction: 'none', userSelect: 'none', WebkitTouchCallout: 'none', WebkitUserModify: 'read-only', WebkitUserSelect: 'none', KhtmlUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none'}}
            onMouseDown={handleMouseDown}
            onMouseUp={reset}
            onMouseLeave={reset}
            onTouchStart={handleMouseDown}
            onTouchEnd={reset}
            onTouchCancel={reset}
            onTouchMove={e => { e.preventDefault(); }}
            onContextMenu={(e) => {
                e.preventDefault();
            }}
        >
            {isHeld ? 'Hold for 5 seconds...' : props.title}
        </button>
      </>
    );
}
