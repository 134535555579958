import { useEffect } from "react";

const statusStyles = {
  success: "bg-green-100 text-green-800",
  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-gray-100 text-gray-800",
};

export type Column<Row> = {
  renderHeader: (i: number) => JSX.Element;
  renderRow: (row: Row, rowI: number, colI: number) => JSX.Element;
};

export type Props<Row> = {
  title: string | JSX.Element;
  columns: Column<Row>[];
  rows: Row[];
  showAddButton?: boolean;
  currentPage: number;
  pageSize: number;
  totalRows: number;
  removePadding?: boolean;
  showPagination?: boolean;
  className?: string;
  onAdd?: () => void;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onRowClick?: (row: Row) => void;
};

export default function Table<Row>(props: Props<Row>): JSX.Element {
  // Not sure that I need this
  // useEffect(() => {
  //   // console.log("Table props", props);
  //   // console.log("Table Rows", props.rows);
  // }, [props.columns, props.rows]);

  const AddButton = () => {
    return (
      <button
        type="button"
        className="grow-0 rounded-full border border-transparent bg-indigo-600 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
        onClick={props.onAdd && props.onAdd}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    );
  };

  const Rows = () => {
    return (
      <>
        {props.rows.length > 0 ? (
          props.rows.map((row, i) => (
            <tr
              key={i}
              onClick={() => {
                props.onRowClick && props.onRowClick(row);
              }}
              className="bg-white cursor-pointer hover:bg-gray-100"
            >
              {props.columns.map((column, j) => column.renderRow(row, i, j))}
            </tr>
          ))
        ) : (
          <tr>
            <td
              className="bg-white px-6 py-4 text-sm font-semibold text-gray-900 text-center"
              colSpan={4}
            >
              No data found
            </td>
          </tr>
        )}
      </>
    );
  };

  const Navigation = () => {
    return (
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-2 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-xs text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {props.totalRows > 0
                ? props.pageSize * (props.currentPage - 1) + 1
                : 0}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {props.totalRows < props.pageSize * props.currentPage
                ? props.totalRows
                : props.pageSize * props.currentPage}
            </span>{" "}
            of <span className="font-medium">{props.totalRows}</span> results
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <button onClick={props.onPreviousPage}>
            <a
              href="#"
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </a>
          </button>
          <button
            onClick={props.onNextPage}
            disabled={props.totalRows < props.pageSize * props.currentPage}
          >
            <a
              href="#"
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </a>
          </button>
        </div>
      </nav>
    );
  };

  return (
    <main className={props.className + " flex-1 pb-8"}>
      <div className="pt-3">
        <div className="flex flex-row-reverse mx-auto mt-2 max-w-6xl px-4 text-md font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
          {props.showAddButton && (
            <AddButton></AddButton>
          )}
        </div>

        <div className={`flex mx-auto mt-2 max-w-6xl ${props.removePadding ? "" : "px-4 sm:px-6 lg:px-8"} text-md font-medium leading-6 text-gray-900`}>
          <h2 className="grow-2">{props.title}</h2>
        </div>

        {/* <div className="hidden sm:block"> */}
        <div className=" sm:block">
          <div className={`mx-auto max-w-6xl ${props.removePadding ? "" : "px-4 sm:px-6 lg:px-8"}`}>
            <div className="mt-2 flex flex-col">
              <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>{props.columns.map((column, i) => column.renderHeader(i))}</tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <Rows></Rows>
                  </tbody>
                </table>

                {props.showPagination !== false && <Navigation></Navigation>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
