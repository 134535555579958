import L from "leaflet";
import React, { Ref, useEffect, useRef, useState } from "react";
import { Circle, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { Site } from "../../site/Site";
import { fetchWithAuth } from "../../../utils/apiUtils";

var windmillIcon = new L.Icon({
  iconUrl: require("../../../img/oil-rig.png"),
  iconSize: [30, 30], // size of the icon
});

var contractorIcon = new L.Icon({
  iconUrl: require("../../../img/worker.png"),
  iconSize: [25, 25], // size of the icon
});

var contractorGreenIcon = new L.Icon({
  iconUrl: require("../../../img/worker-with-black.png"),
  iconSize: [25, 25], // size of the icon
});

const mockSite = {
  _id: {
    $oid: "63c4780dd016026a4f2608ce",
  },
  companyId: "1234",
  location: {
    type: "Point",
    coordinates: [-97.603453, 35.751191],
  },
  detail: {
    apiNumber: "35083243560001",
    wellDescription: "Well 1-1-1",
    ranking: 3,
    workingInterest: 0.91,
    notes: "",
    wellId: "COLLINWELL20",
  },
  __v: 0,
};

function SmoothZoomToBounds({ bounds, animate }: any) {
  const map = useMap();
  const previousBoundsRef = useRef(null);

  useEffect(() => {
    const previousBounds = previousBoundsRef.current;

    if (previousBounds && bounds !== previousBounds) {
      map.flyToBounds(bounds, {
        animate: animate || false,
        duration: 2,
        easeLinearity: .99,
        padding: [25, 25],
      });
    }

    previousBoundsRef.current = bounds;
  }, [bounds, animate, map]);

  return null;
}

export default function Map(props: {
  site: Site;
}) {
  const [operators, setOperators] = React.useState<any>([]);
  const [showMap, setShowMap] = useState(false);
  const [bounds, setBounds] = useState<any>([]);

  useEffect(() => {
    setShowMap(true);
    window.dispatchEvent(new Event("resize")); // trigger resize event
  }, []);

  const getClosestOperator = async (siteId: string) => {
    const closestOperatorsResults = await fetchWithAuth(`closest-operators/${siteId}`, {});

    const clostestOperators = await closestOperatorsResults.json();

    if (closestOperatorsResults.ok) {
      const closestOperator = clostestOperators[0];
      setOperators([closestOperator]);

      const bounds = L.latLngBounds([
        [props.site.location.coordinates[0], props.site.location.coordinates[1]],
        [closestOperator.detail.location.coordinates[1], closestOperator.detail.location.coordinates[0]]
      ]);
      setBounds(bounds);
    }
  };
  // const position = [55.2854062, 23.9327383];
  // const initialZoom = 8;
  // let [showTooltip, setShowTooltip] = React.useState(false);

  useEffect(() => {
    // mapRef.current.leafletElement.locate();
    getClosestOperator(props.site._id);
  }, [props.site]);

  return (
    <div
      className="m-auto align-middle shadow sm:rounded-lg"
      style={{ height: "400px", width: "400px" }}
    >
      <div className="divide-y divide-gray-200">
        <div className="bg-gray-50  text-left text-sm font-semibold text-gray-900 rounded-md">
          {showMap && (
            <MapContainer
              style={{ height: "400px", width: "400px" }}
              className="mt-8"
              center={[props.site.location.coordinates[0], props.site.location.coordinates[1]]}
              zoom={7}
              scrollWheelZoom={true}
              zoomControl={false}
              attributionControl={false}
            >
              <SmoothZoomToBounds bounds={bounds} animate={true} />
              <TileLayer
                // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {mockSite && (
                <>
                  <Marker
                    icon={windmillIcon}
                    position={[
                      props.site.location.coordinates[0],
                      props.site.location.coordinates[1],
                    ]}
                  >
                    {/* <Popup>
                    <div>
                      <span className="font-bold">Windmill ID: </span>
                      {data[0]._id}
                    </div>
                    <div>
                      <span className="font-bold">Alert Type: </span>
                      {
                        //@ts-ignore
                        valueMap[data[0].alertType]
                      }
                    </div>
                  </Popup> */}
                  </Marker>
                  {/* <Circle
                    center={[
                      props.site.location.coordinates[0],
                      props.site.location.coordinates[1],
                    ]}
                    pathOptions={{ color: "blue" }}
                    radius={96560.6}
                  /> */}
                </>
              )}
              {operators.length > 0 &&
                operators.map((contractor: any, i: number) => (
                  <Marker
                    icon={i === 0 ? contractorGreenIcon : contractorIcon}
                    key={i}
                    position={[
                      contractor.detail.location.coordinates[1],
                      contractor.detail.location.coordinates[0],
                    ]}
                  >
                    {/* <Popup>{contractor.firstName + " " + contractor.lastName}</Popup> */}
                  </Marker>
                ))}
            </MapContainer>
          )}
        </div>
      </div>
    </div>
  );
}
