import { io, Socket } from 'socket.io-client';

export default class SocketSingleton {
    private static instance: SocketSingleton;
    private socket: Socket;
    private isSocketConnected: boolean = false;
    private listeners: { [event: string]: (...args: any[]) => void } = {
        'connect': () => {
            console.log('Socket connected');
            this.isSocketConnected = true;
        },
        'error': (err: any) => {
            console.log('Socket error:', err);
            this.reconnect();
        },
        'disconnect': () => {
            console.log('Socket disconnected');
            this.isSocketConnected = false;
        }
    };

    private constructor() {
        // Private constructor to prevent external instantiation
        this.socket = io(`${process.env.REACT_APP_FIELD_API_WS_HOST}/?token=${localStorage.getItem("accessToken")}`, {
            path: '/field-api/socket.io',
        });
        this.setupListeners();
    }

    public static getInstance(): SocketSingleton {
        if (!SocketSingleton.instance) {
            SocketSingleton.instance = new SocketSingleton();
        }
        return SocketSingleton.instance;
    }

    private setupListeners(): void {
        for (const event in this.listeners) {
            this.socket.on(event, this.listeners[event]);
        }
    }

    private removeListeners(): void {
        for (const event in this.listeners) {
            this.socket.off(event, this.listeners[event]);
        }
    }

    public on(event: string, callback: (...args: any[]) => void): void {
        this.listeners[event] = callback;
        this.socket.on(event, callback);
    }

    public off(event: string): void {
        delete this.listeners[event];
        this.socket.off(event);
    }

    public emit(event: string, data: any): void {
        if (!this.isSocketConnected) {
            this.reconnect();
        }
        this.socket.emit(event, data, (res: any) => {
            if (res?.success !== true) {
                this.reconnect();
            }
            console.log("Socket Acknowledgment data:", res);
        });
    }

    public getSocket(): Socket {
        return this.socket;
    }

    public isConnected(): boolean {
        return this.isSocketConnected;
    }

    public disconnect(): void {
        this.socket.disconnect();
        this.removeListeners();
        this.isSocketConnected = false;
    }

    // This method should be called if 401 error is ever received from the server, or if the socket connection is ever lost
    public reconnect(): void {
        this.disconnect();
        // this.socket = io(`${process.env.REACT_APP_FIELD_API}/field-api/?token=${localStorage.getItem("accessToken")}`);
        this.socket = io(`${process.env.REACT_APP_FIELD_API_WS_HOST}/?token=${localStorage.getItem("accessToken")}`, {
            path: '/field-api/socket.io',
        });
        this.setupListeners();
    }
}