import "./App.css";
import DashboardPage from "./components/dashboard";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate
} from "react-router-dom";
import Navbar from "./components/nav";
import ErrorPage from "./components/error";
import OperatorPage from "./components/operator";
import WeightPage from "./components/weight";
import SitePage from "./components/site";
import Example, { CompanySettings, SettingWeight } from "./components/setting";
import Inbox from "./components/inbox";
import { Login } from "./components/login";
import jwt_decode from "jwt-decode";
import { IndependentOperatorPage } from "./components/indpendentOperator";
import React, { useEffect } from "react";
import Sidebar from "./components/inbox/SideBar";
import Conversation from "./components/inbox/Conversation";
import WaitingScreen from "./components/indpendentOperator/waiting";
import { logout } from "./utils/apiUtils";
import { InboxView } from "./components/inbox/InboxView";
import { SiteView } from "./components/site/SiteView";
import InternalOperatorPage from "./components/internalOperator";
import { ConfigProvider } from "./components/common/config-context/ConfigContext";

function App() {
  
  return (
    <>
      {/* <ConfigProvider> */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateRoutes userType={'MANAGER'} component={<ConfigProvider><LayoutsWithNavbar></LayoutsWithNavbar></ConfigProvider>}></PrivateRoutes>}>
              <Route path="/" element={<DashboardPage />} />
              {/* <Route path="/operatorss" element={<OperatorPage />} /> */}
              <Route path="/internal-operators" element={<InternalOperatorPage />} />
              
              {/* <Route path="/inbox" element={<Inbox />} /> */}
              <Route path="/inbox" element={<Inbox />} />
              <Route path="/inbox/:conversationId" element={<InboxView />} />


              {/* <Route path="/inbox" element={<div className="flex">
                
              </div>} /> */}
              <Route path="/weight" element={<WeightPage />} />

              <Route path="/site" element={<SitePage />} />
              <Route path="/site/:siteId" element={<SiteView />} />


              <Route path="/setting/profile" element={<Example />} />
              <Route path="/setting/company" element={<CompanySettings />} />
              <Route path="/setting/weight" element={<SettingWeight />} />
              <Route path="*" element={<ErrorPage></ErrorPage>} />
            </Route>
            <Route path="/operator" element={<PrivateRoutes userType={'INDEPENDENT_OPERATOR'} component={<Outlet />}></PrivateRoutes>}>
              <Route path="/operator" element={<IndependentOperatorPage></IndependentOperatorPage>} />
              {/* <Route path="/indpendent" element={<WaitingScreen message="Waiting for compatible jobs." subMessage="Please note that this process may take some time. To indicate your availability for new jobs, please keep this webpage open."></WaitingScreen>} /> */}
              <Route path="*" element={<ErrorPage></ErrorPage>} />
            </Route>

            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      {/* </ConfigProvider> */}
      {/* <LocationTest></LocationTest> */}
    </>
  );
}


function LocationTest() {
  const [coords, setCoords] = React.useState({
    latitude: 0,
    longitude: 0
  });

  const [count, setCount] = React.useState(0);
  const [geo, setGeo] = React.useState<any>();


  const updateLocation = async () => {
    console.log("Attempting to update location");
    console.log(navigator.geolocation)
    setGeo(navigator.geolocation);
    if (navigator.geolocation) {
      setCount(count + 1);
      navigator.geolocation.getCurrentPosition(async function (position) {
        const token = localStorage.getItem('token');
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        setCoords({
          latitude,
          longitude
        });



        const response = await fetch(`${process.env.REACT_APP_FIELD_API}/location`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            "location": {
              "type": "Point",
              "coordinates": [latitude, longitude]
            }
          }),
        });
        if (response.ok) {
          console.log("Location updated");
        }

        // Do something with the latitude and longitude, such as displaying it on a map
      });
    } else {
      // Geolocation is not supported by this browser
    }
  };

  useEffect(() => {
    // updateLocation();
    const interval = setInterval(async () => {
      await updateLocation();
    }, 10 * 1000); // 10 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {

  }, [count]);

  return (
    <div>test
      <p>Count: {count}</p>
      <p>Latitude: {coords.latitude}</p>
      <p>Longitude: {coords.longitude}</p>
      <p>Geo: {JSON.stringify(geo)}</p>
    </div>
  );
}

function LayoutsWithNavbar() {
  return (
    <>
      {/* Your navbar component */}
      <div className="sticky top-0 z-[1000]">
        <Navbar></Navbar>
      </div>

      {/* This Outlet is the place in which react-router will render your components that you need with the navbar */}
      <Outlet />

      {/* You can add a footer to get fancy in here :) */}
    </>
  );
}

const PrivateRoutes = ({ userType, component }: { userType: string, component: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('accessToken');
  let authenticated = false;

  if (token) {
    const decoded: any = jwt_decode(token || '');
    if (decoded?.userType === userType) {
      const currentTime = Date.now() / 1000;
      if (decoded?.exp < currentTime) {
        logout();
        navigate('/login');
      } else {
        authenticated = true;
      }
    }
  }

  return authenticated
    ? component
    : <Navigate to="/login" replace state={{ from: location }} />;
}


export default App;
