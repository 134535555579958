import React, { useState } from 'react';

function validateApiNumber(apiNumber: string): boolean {
    // Check if the API number is a string with 14 digits
    if (!/^\d{14}$/.test(apiNumber)) {
        return false;
    }

    // Extract the state code, county code, and unique well identifier
    const stateCode = apiNumber.substr(0, 2);
    const countyCode = apiNumber.substr(2, 3);
    const uniqueWellId = apiNumber.substr(5, 5);
    const sidetrackCode = apiNumber.substr(10, 2);
    const eventSequenceCode = apiNumber.substr(12, 2);

    // Check if the codes are digits
    if (!/^\d{2}$/.test(stateCode) || !/^\d{3}$/.test(countyCode) || !/^\d{5}$/.test(uniqueWellId)
        || !/^\d{2}$/.test(sidetrackCode) || !/^\d{2}$/.test(eventSequenceCode)) {
        return false;
    }

    // Check if the sidetrack code is in the range 00-99 and the event sequence code is in the range 00-99
    if (parseInt(sidetrackCode) < 0 || parseInt(sidetrackCode) > 99 || parseInt(eventSequenceCode) < 0 || parseInt(eventSequenceCode) > 99) {
        return false;
    }

    return true;
}

export function insertDashes(apiNumber: string): string {
    const length = apiNumber.length;
    if (length <= 2) {
        return apiNumber;
    } else if (length <= 5) {
        return apiNumber.slice(0, 2) + "-" + apiNumber.slice(2);
    } else if (length <= 10) {
        return apiNumber.slice(0, 2) + "-" + apiNumber.slice(2, 5) + "-" + apiNumber.slice(5);
    } else if (length <= 12) {
        return apiNumber.slice(0, 2) + "-" + apiNumber.slice(2, 5) + "-" + apiNumber.slice(5, 10) + "-" + apiNumber.slice(10);
    } else {
        return apiNumber.slice(0, 2) + "-" + apiNumber.slice(2, 5) + "-" + apiNumber.slice(5, 10) + "-" + apiNumber.slice(10, 12) + "-" + apiNumber.slice(12);
    }
}

export type ApiInputProps = {
    apiNumber: string;
    setApiNumber: (apiNumber: string) => void;
    readonly?: boolean;
}

function ApiInput(props: ApiInputProps) {
    // const [apiNumber, setApiNumber] = useState("");
    const [isValid, setIsValid] = useState(true);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newApiNumber = event.target.value.replace(/-/g, "");
        props.setApiNumber(newApiNumber);
        setIsValid(validateApiNumber(newApiNumber));
    }

    return (
        <>
            <label
                htmlFor="api-number"
                className="block text-sm font-medium text-gray-700"
            >
                API Number ( 14 digits )
            </label>
            <input
                readOnly={props.readonly}
                type="text"
                name="apiNumber"
                id="api-number"
                maxLength={18}
                value={insertDashes(props.apiNumber)}
                onChange={handleChange}
                autoComplete="off"
                className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
            />
            {!isValid && <div style={{ color: "red" }}>Invalid API Number</div>}
        </>
    );
}

export default ApiInput;
