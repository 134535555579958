import { useEffect, useState } from "react";
import { Company } from "../../models/Company";
import Table, { Column } from "../common/table";
// import { Operator, UserType } from "./Operator";
// import OperatorFilter, { OperatorReviewStatus } from "./OperatorFilter";
// import OperatorModal from "./OperatorModal";
// import { formatUSPhoneNumber } from "./util";
import { fetchWithAuth } from "../../utils/apiUtils";
import { Operator, UserType } from "../operator/Operator";
import OperatorFilter, { OperatorReviewStatus } from "../operator/OperatorFilter";
import { formatUSPhoneNumber } from "../operator/util";
import OperatorModal from "../operator/OperatorModal";

const columns: Column<Operator>[] = [
  {
    renderHeader: (i: number) => (
      <th
        className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        Name
      </th>
    ),
    renderRow: (row, i) => (
      <td className="w-1/3 max-w-0 whitespace-nowrap px-6 py-2 text-xs text-gray-900">
        <div className="flex">
          <p className="truncate text-gray-500 group-hover:text-gray-900">
            {row.firstName} {row.lastName}
          </p>
        </div>
      </td>
    ),
  },
  {
    renderHeader: (i: number) => (
      <th
        key={i}
        className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        Phone #
      </th>
    ),
    renderRow: (row, i) => (
      <td className="w-1/3 max-w-0 whitespace-nowrap px-6 py-2 text-xs text-gray-900">
        <div className="flex">
          <p className="truncate text-gray-500 group-hover:text-gray-900">
            {formatUSPhoneNumber(row.phoneNumber)}
          </p>
        </div>
      </td>
    ),
  },
  {
    renderHeader: (i: number) => (
      <th
        key={i}
        className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
        scope="col"
      >
        On Call
      </th>
    ),
    renderRow: (row, i) => (
      <td className="w-1/3 max-w-0 whitespace-nowrap px-6 py-2 text-xs text-gray-900">
        <div className="flex">
          <p className="truncate text-gray-500 group-hover:text-gray-900">
            {row.userType}
          </p>
        </div>
      </td>
    ),
  },
];

export default function InternalOperatorPage() {
  const [operatorModalOpen, setOperatorModalOpen] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState<Operator>();

  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [company, setCompany] = useState<Company>();
  const [operatorFilter, setOperatorFilter] = useState(OperatorReviewStatus.ALL);

  // const fetchCompany = async () => {
  //   const token = localStorage.getItem('token');
  //   const companyResults = await fetch(
  //     `${process.env.REACT_APP_FIELD_API}/company/${"63d42d1cff3457bf9be6de72"}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );
  //   const fetchedCompany = await companyResults.json();

  //   if (companyResults.ok) {
  //     setCompany(fetchedCompany);
  //     // setOperatorFilter(fetchedCompany.rejectedOperatorIds);
  //   }
  // };

  const fetchData = async () => {
    const params = new URLSearchParams();
    params.append("userType", UserType.INDEPENDENT_OPERATOR);
    params.append("page", (page - 1).toString());
    params.append("size", pageSize.toString());

    const operatorsResults = await fetchWithAuth(`users?${params.toString()}`, {});
    const fetchedOperators = await operatorsResults.json();

    if (operatorsResults.ok) {
      setRows(fetchedOperators.data);
      setTotalRows(fetchedOperators.total);
    }
  };

  const rejectOperator = async (operatorId: string) => {
    const params = new URLSearchParams();
    params.append("operatorId", operatorId);
    params.append("companyId", "63d42d1cff3457bf9be6de72");

    const operatorsResults = await fetch(
      `${process.env.REACT_APP_FIELD_API}/company/rejectOperator?${params.toString()}`,
      { method: "PUT" }
    ).then(() => fetchData());
  };

  const acceptOperator = async (operatorId: string) => {
    const params = new URLSearchParams();
    params.append("operatorId", operatorId);
    params.append("companyId", "63d42d1cff3457bf9be6de72");

    const operatorsResults = await fetch(
      `${process.env.REACT_APP_FIELD_API}/company/acceptOperator?${params.toString()}`,
      { method: "PUT" }
    ).then(() => fetchData());
  };

  const goToPreviousPage = () => {
    setPage((prev) => {
      if (prev <= 1) {
        return 1;
      }
      return prev - 1;
    });
  };

  const goToNextPage = () => {
    setPage((prev) => {
      if (prev >= totalRows / pageSize) {
        return totalRows / pageSize;
      }
      return prev + 1;
    });
  };

  // useEffect(() => {
  //   fetchCompany();
  // }, []);

  useEffect(() => {
    fetchData();
  }, [page, operatorFilter]);

  const onOperatorFilterChange = (filter: OperatorReviewStatus) => {
    setOperatorFilter(filter);
  };

  const prettyPrintOperatorStatus = (status: OperatorReviewStatus) => {
    switch (status) {
      case OperatorReviewStatus.ACCEPTED:
        return "Accepted";
      case OperatorReviewStatus.REJECTED:
        return "Rejected";
      case OperatorReviewStatus.NOT_PROCESSED:
        return "Not Processed";
      case OperatorReviewStatus.ALL:
        return "All";
    }
  };

  return (
    <>
      {selectedOperator && (
        <OperatorModal
          operator={selectedOperator}
          open={operatorModalOpen}
          accepted={company?.acceptedOperatorIds?.includes(selectedOperator._id)}
          rejected={company?.rejectedOperatorIds?.includes(selectedOperator._id)}
          setOpen={setOperatorModalOpen}
          onAccept={acceptOperator}
          onReject={rejectOperator}
        ></OperatorModal>
      )}

      <main className="flex-1 pb-8">
        <div className="pt-3">
          {/* <div className="flex flex-row-reverse mx-auto mt-2 max-w-6xl px-4 text-md font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
            <OperatorFilter
              selected={operatorFilter}
              onSelected={onOperatorFilterChange}
            ></OperatorFilter>
          </div> */}
          <Table
            columns={columns}
            rows={rows}
            title={prettyPrintOperatorStatus(operatorFilter) + " Operators"}
            onNextPage={goToNextPage}
            onPreviousPage={goToPreviousPage}
            currentPage={page}
            pageSize={pageSize}
            totalRows={totalRows}
            onRowClick={(row: Operator) => {
              setSelectedOperator(row);
              setOperatorModalOpen(true);
            }}
          ></Table>
        </div>
      </main>
    </>
  );
}
