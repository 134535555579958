import { Bars3Icon } from '@heroicons/react/24/outline';
import React from 'react';
import Text from "../../../img/text.png";

interface WaitingScreenProps {
    message: string;
    subMessage?: string;
}

const WaitingScreen: React.FC<WaitingScreenProps> = ({ message, subMessage }) => {
    return (
        <div className="bg-gray-100 max-h-screen flex flex-col">
            <nav className="bg-black w-full mx-0 px-0">
                <div className="flex items-center">
                    <div className="logo p-4">
                        <img src={Text} width="150" alt="Your Company" />
                    </div>
                    <p className="text-xl font-bold text-white">Operator</p>
                </div>
            </nav>
            <div className="flex flex-col items-center justify-center h-screen text-red text-center">
                <p className="text-xl font-bold mb-2">{message}</p>
                <p className="text-xs font-bold mb-4 mx-2">{subMessage}</p>
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-400"></div>
            </div>
        </div>
    );
};

export default WaitingScreen;
