import React from "react";
import { fetchWithAuth } from "../../utils/apiUtils";
import Identicon from 'identicon.js';
import { Job, JobStatus } from "../dashboard";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../common/config-context/ConfigContext";

interface ConversationPreview {
  jobId: string;
  siteId: string;
  wellId: string;
  status: JobStatus;
}

interface SidebarProps {
  selectConversation: (jobId: string) => void;
  conversationsExist: boolean;
  setConversationsExist: (conversationsExist: boolean) => void;
  className?: string;
}

const Sidebar = (props: SidebarProps) => {
  const navigate = useNavigate();
  const iconSize = 40;
  const [conversationPreviews, setConversationPreviews] = React.useState<any>([]);
  const config = useConfig();
  const getSiteLabel = config?.siteTitle || 'Site';

  const fetchConversations = async () => {
    const conversationResults = await fetchWithAuth("conversations", {});
    const fetchedConversations = await conversationResults.json();

    if (conversationResults.ok) {
      const conversationPreviews: ConversationPreview[] = fetchedConversations.map((conversation: Job) => ({
        jobId: conversation._id,
        siteId: conversation.siteId,
        wellId: conversation.wellId,
        status: conversation.status,
      }));
      console.log('aaaa', conversationPreviews)

      setConversationPreviews(conversationPreviews);
      if (fetchedConversations.length > 0) {
        props.setConversationsExist(true);
      }
    }
  };

  const createAvatar = (jobId: string) => {
    const data = new Identicon(jobId, {
      size: iconSize,
      background: [255, 255, 255, 255],
      margin: 0.05,
    }).toString();

    return <img src={`data:image/png;base64,${data}`} width={iconSize} height={iconSize} alt="Avatar" />;
  };

  const shrinkJobId = (jobId: string) => {
    return jobId.substring(0, 5) + "..." + jobId.substring(jobId.length - 5, jobId.length);
  };

  const goToSiteDetail = (siteId: string) => {
    navigate(`/site/${siteId}`);
  };

  React.useEffect(() => {
    fetchConversations();
  }, []);

  return (
    // <div className={props.className + " w-1/4 bg-gray-800"}>
    <div className={props.className + " w-1/4 bg-gray-800"}>
      <div className="flex items-center justify-center h-16 text-white text-xl font-bold">
        Conversations
      </div>
      <div className="overflow-y-auto">
        {false ? <div className="items-center justify-center h-16 text-white text-sm font-semibold">No Conversations yet.</div> :
          conversationPreviews.map((preview: ConversationPreview) => (
            <div
              key={preview.jobId}
              className="p-4 border-b border-gray-700 cursor-pointer hover:bg-gray-700"
              onClick={() => props.selectConversation(preview.jobId)}
            >
              <div className="items-center">
                <div className="text-sm flex justify-between items-center">
                  <div>
                    <div className="font-semibold text-white">{getSiteLabel} ID: {preview.wellId}</div>
                    <div className="text-gray-400">Status: {preview.status}</div>
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      goToSiteDetail(preview.siteId)
                    }}
                  >
                    <a
                      href="#"
                      className="relative ml-3  items-center rounded-md  px-4 py-1 text-xs font-medium text-gray-400 hover:bg-gray-600"
                    >
                      View Site
                    </a>
                  </button>
                </div>

              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Sidebar;




// <div className="flex flex-col w-1/4 bg-gray-800">
//       <div className="flex items-center justify-center h-16 text-white text-xl font-bold">
//         Conversations
//       </div>
//       <div className="overflow-y-auto">
//         {false ? <div className="flex items-center justify-center h-16 text-white text-sm font-semibold">No Conversations yet.</div> :
//           conversationPreviews.map((preview: ConversationPreview) => (
//             <div
//               key={preview.jobId}
//               className="p-4 border-b border-gray-700 cursor-pointer hover:bg-gray-700"
//               onClick={() => props.selectConversation(preview.jobId)}
//             >
//               <div className="flex items-center">
//                 <div className="flex-grow text-sm flex justify-between items-center">
//                   <div>
//                     <div className="font-semibold text-white">Well ID: {preview.wellId}</div>
//                     <div className="text-gray-400">Status: {preview.status}</div>
//                   </div>
//                   <button
//                     onClick={(e) => {
//                       e.preventDefault();
//                       e.stopPropagation();
//                       goToSiteDetail(preview.siteId)
//                     }}
//                   >
//                     <a
//                       href="#"
//                       className="relative ml-3 inline-flex items-center rounded-md  px-4 py-1 text-xs font-medium text-gray-400 hover:bg-gray-600"
//                     >
//                       View Site
//                     </a>
//                   </button>
//                 </div>

//               </div>
//             </div>
//           ))}
//       </div>
//     </div>
