import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Text from "../../img/fieldgistix.png";
import jwt_decode from "jwt-decode";

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginMsg, setLoginMsg] = useState<any>(null);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    login();
  }

  const login = async () => {
    const loginResults = await fetch(`${process.env.REACT_APP_FIELD_API}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
    const fetchedLogin = await loginResults.json();

    if (loginResults.ok) {
      localStorage.setItem('accessToken', fetchedLogin.access_token);
      localStorage.setItem('refreshToken', fetchedLogin.refresh_token);

      const decoded: any = jwt_decode(fetchedLogin.access_token || '');

      if (decoded?.userType === 'MANAGER') {
        navigate('/');
      } else {
        navigate('/operator');
      }
    }

    if (loginResults.status == 401) {
      setLoginMsg('Invalid email or password.');
    }
  };

  return (
    // <div className="flex flex-col items-center justify-center h-screen bg-slate-100">
    //     <div className="flex flex-col items-center justify-center bg-white rounded-lg shadow-lg p-10 w-1/4">
    //         <div className="flex flex-shrink-0 items-center mb-5">
    //             <img
    //                 className="block h-15 w-auto"
    //                 src={Text}
    //                 alt="Your Company"
    //             />
    //         </div>
    //         <h1 className="text-2xl font-bold mb-5">Login</h1>
    //         <form className="flex flex-col items-center justify-center">
    //             <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" className="border border-gray-400 p-2 rounded-lg mb-5" />
    //             <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" className="border border-gray-400 p-2 rounded-lg mb-5" />
    //             <p className="text-red-500 mb-5">{loginMsg}</p>
    //             <button onClick={handleSubmit} className="text-white p-2 rounded-lg w-1/2" style={{backgroundColor: 'rgb(94,0,240)'}}>Login</button>
    //         </form>
    //         <a href="#" className="text-blue-500 mt-5">Forgot Password?</a>
    //     </div>
    // </div>
    // Make this login work for both desktop and mobile
    <div className="flex flex-col items-center justify-center h-screen bg-slate-100">
      <div className="flex flex-col items-center justify-center bg-white rounded-lg shadow-lg p-5 md:p-10 w-full md:w-1/4">
        <div className="flex flex-shrink-0 items-center mb-5">
          <img className="block h-15 w-auto" src={Text} alt="Your Company" />
        </div>
        <h1 className="text-2xl font-bold mb-5">Login</h1>
        <form className="flex flex-col items-center justify-center w-full">
          <label className="sr-only" htmlFor="email">Email</label>
          <input
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
            className="border border-gray-400 p-3 rounded-lg mb-3 w-full"
            autoComplete="username"
            autoCapitalize="none"
            autoCorrect="off"
            spellCheck="false"
            required
          />
          <label className="sr-only" htmlFor="password">Password</label>
          <input
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            className="border border-gray-400 p-3 rounded-lg mb-3 w-full"
            autoComplete="current-password"
            required
          />
          <p className="text-red-500 mb-3">{loginMsg}</p>
          <button
            onClick={handleSubmit}
            className="text-white bg-purple-600 p-3 rounded-lg w-full"
            type="submit"
          >
            Login
          </button>
        </form>
        <a href="#" className="text-blue-500 mt-3">Forgot Password?</a>
      </div>
    </div>

  );
}

// r 199
// g
// b 250