import { Switch } from "@headlessui/react";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export type Weight = { weight: number; positiveImpact: boolean };

export function WeightControl(props: {
  weight: Weight;
  label: string;
  onWeightChange: (weight: number) => void;
  onPositiveImpactChange: (positiveImpact: boolean) => void;
}) {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{props.label}</td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
        <input
          type="number"
          name="name"
          id="name"
          className="block w-full border-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm rounded-3xl"
          step={0.05}
          max={1}
          min={0}
          placeholder=".05"
          value={props.weight.weight}
          onChange={(e) => props.onWeightChange(parseFloat(e.target.value))}
        />
      </td>
      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
        <Switch
          checked={props.weight.positiveImpact}
          onChange={props.onPositiveImpactChange}
          className={classNames(
            props.weight.positiveImpact ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              props.weight.positiveImpact ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      </td>
    </tr>
  );
}
