import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { JobWithAlerts } from "../../models/JobWithAlerts";
import Table, { Column } from "../common/table";
import Map from "./map";
// import { io } from "socket.io-client";
import { DateTime } from "luxon";
import { Alert } from "../../models/Alert";
import { fetchWithAuth } from "../../utils/apiUtils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SocketSingleton from "../../services/socket.service";
import { useConfig } from "./config-context/ConfigContext";


function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const statusStyles = {
  COMPLETED: "bg-green-100 text-green-800",
  PENDING: "bg-yellow-100 text-yellow-800",
  PENDING_MANAGER_APPROVAL: "bg-yellow-100 text-yellow-800",
  PENDING_OPERATOR_APPROVAL: "bg-yellow-100 text-yellow-800",
  ASSIGNED: "bg-blue-100 text-blue-800",
  UNASSIGNED: "bg-gray-100 text-red-800",
};

// const columns: Column<Alert>[] = [
//   {
//     // bg-red-400
//     renderHeader: (i: number) => (
//       <th
//         key={i}
//         className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
//         scope="col"
//       >
//         Well ID
//       </th>
//     ),
//     renderRow: (row, rowI, colI) => (
//       <td
//         key={colI}
//         className={`whitespace-nowrap px-6 py-2 text-left text-xs ${
//           rowI == 0 ? "bg-red-400" : ""
//         } text-gray-500`}
//       >
//         {/* <span className="font-medium text-gray-900">{transaction.amount}</span> */}
//         {/* @ts-ignore */}
//         {row.detail && row.detail.wellId} 
//       </td>
//     ),
//   },
//   {
//     // bg-red-400
//     renderHeader: (i: number) => (
//       <th
//         key={i}
//         className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
//         scope="col-2"
//       >
//         Alert Detail
//       </th>
//       // Make this column smaller
//     ),
//     renderRow: (row, rowI, colI) => (
//       <td
//         key={colI}
//         className={`whitespace-nowrap px-6 py-2 text-left text-xs ${
//           rowI == 0 ? "bg-red-400" : ""
//         } text-gray-500`}
//       >
//         {/* @ts-ignore */}
//         {row.detail && row.detail.alertDetail}
//       </td>
//     ),
//   },
//   {
//     renderHeader: (i: number) => (
//       <th
//         key={i}
//         className="bg-gray-50 px-6 py-2 text-right text-sm font-semibold text-gray-900"
//         scope="col"
//       >
//         Alerted At
//       </th>
//     ),
//     renderRow: (row, rowI, colI) => (
//       <td
//         key={colI}
//         className={`whitespace-nowrap px-6 py-2 text-right text-xs text-gray-500 ${
//           rowI == 0 ? "bg-red-400" : ""
//         }`}
//       >
//         {/* @ts-ignore */}
//         <time dateTime={row.createTimestamp && row.createTimestamp}>
//           {/* @ts-ignore */}
//           {DateTime.fromISO(row.createTimestamp && row.createTimestamp).toFormat("ccc t")}
//         </time>
//       </td>
//     ),
//   },
// ];

// const socket = io(`${process.env.REACT_APP_FIELD_API}`);
const socket = SocketSingleton.getInstance();

export default function ConfirmOperatorModal(props: {
  jobId?: string | null;
  onClose?: () => void;
}) {
  const [job, setJob] = useState<JobWithAlerts>();
  const [alerts, setAlerts] = useState<Alert[]>();
  const cancelButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.isConnected());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const config = useConfig();
  const getSiteLabel = config?.siteTitle || 'Site';

  const getJob = async (jobId: string) => {
    const jobResults = await fetchWithAuth(`job/${jobId}`, {});
    const fetchedJob = await jobResults.json();

    if (jobResults.ok) {
      setJob(fetchedJob);
      getAlerts();
      setIsOpen(true);
    }
  };

  const acceptOperator = async (jobId: string) => {
    const companyResults = await fetchWithAuth(`job/test/approve/${jobId}`, {
      method: "PUT",
    });

    if (!companyResults) setIsOpen(false);
  };

  const getAlerts = async () => {
    const alertResults = await fetchWithAuth('prioritized-alerts', {});
    const fetchedAlerts = await alertResults.json();

    if (alertResults.ok) {
      setAlerts(fetchedAlerts);
    }
  };

  useEffect(() => {
    socket.on("events", (event) => {
      setJob(event.job);
      getAlerts();
      setIsOpen(true);
    });

    if (!job) setIsOpen(false);
    else {
      // @ts-ignore
      // mockData.unshift(job.job);
    }

    return () => {
      socket.off("events");
    };
  }, []);

  useEffect(() => { }, [job, alerts]);

  useEffect(() => {
    if (props.jobId) {
      getJob(props.jobId);
    }
  }, [props.jobId]);

  const columns: Column<Alert>[] = [
    {
      // bg-red-400
      renderHeader: (i: number) => (
        <th
          key={i}
          className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
          scope="col"
        >
          {getSiteLabel} ID
        </th>
      ),
      renderRow: (row, rowI, colI) => (
        <td
          key={colI}
          className={`whitespace-nowrap px-6 py-2 text-left text-xs ${row.detail.wellId == job?.wellId ? "bg-red-400" : ""
            } text-gray-500`}
        >
          {/* @ts-ignore */}
          {row.detail && row.detail.wellId}
        </td>
      ),
    },
    {
      // bg-red-400
      renderHeader: (i: number) => (
        <th
          key={i}
          className="bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900"
          scope="col-1"
        >
          Alert Detail
        </th>
        // Make this column smaller
      ),
      renderRow: (row, rowI, colI) => (
        <td
          key={colI}
          className={`w-1/2 max-w-0 whitespace-nowrap px-6 py-2 text-left text-xs ${row.detail.wellId == job?.wellId ? "bg-red-400" : ""
            } text-gray-500`}
        >
          {/* @ts-ignore
          {row.detail && row.detail.alertDetail} */}
          <div className="flex">
            {/* IF they hover over, popup the alertDetail */}
            <p className="truncate text-gray-500 group-hover:text-gray-900" title={row.detail && row.detail.alertDetail}>
              {row.detail && row.detail.alertDetail}
            </p>
          </div>
        </td>
      ),
    },
    {
      renderHeader: (i: number) => (
        <th
          key={i}
          className="bg-gray-50 px-6 py-2 text-right text-sm font-semibold text-gray-900"
          scope="col-1"
        >
          Alerted At
        </th>
      ),
      renderRow: (row, rowI, colI) => (
        <td
          key={colI}
          // className={`whitespace-nowrap px-6 py-2 text-right text-xs text-gray-500 ${
          //   rowI == 0 ? "bg-red-400" : ""
          // }`}
          className={`whitespace-nowrap px-6 py-2 text-left text-xs ${row.detail.wellId == job?.wellId ? "bg-red-400" : ""
            } text-gray-500`}
        >
          {/* @ts-ignore */}
          <time dateTime={row.createTimestamp && row.createTimestamp}>
            {/* @ts-ignore */}
            {DateTime.fromISO(row.createTimestamp && row.createTimestamp).toFormat("ccc t")}
          </time>
        </td>
      ),
    },
  ];

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => { }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                <div className="flex justify-between items-center">
                  <h5 className={isMobile ? 'font-bold' : 'text-2xl text'}>Dispatch Operator ( {job?.wellId} )</h5>
                  <button
                    type="button"
                    className="flex rounded-md p-1 border-transparent bg-red-600 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => {
                      if (props.onClose) props.onClose();
                      setIsOpen(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    <XMarkIcon className="h-7 w-7" aria-hidden="true" ></XMarkIcon>
                  </button>
                </div>

                <div className="flex">
                  <div className="w-1/3">
                    {job && !isMobile && (
                      // @ts-ignore
                      <Map site={job.site}></Map>
                    )}
                  </div>
                  <div className="w-2/3">
                    {
                      isMobile ? <div className="my-4 p-2 bg-yellow-200 rounded-lg">View on Desktop to see Alerts!</div> : (
                        <Table
                          columns={columns}
                          rows={alerts || []}
                          title={"Alerts"}
                          onNextPage={() => { }}
                          onPreviousPage={() => { }}
                          currentPage={1}
                          pageSize={1}
                          totalRows={1}
                        ></Table>
                      )
                    }

                    <p className={isMobile ? 'px-2' : 'px-12'}>
                      Would you like us to dispatch the nearest operator equipped with the necessary skills to address the alarm right away?
                    </p>

                    <div className={'flex flex-row-reverse mt-5' + (isMobile ? '' : ' px-8')}>
                      <button
                        type="button"
                        className="mt-3 ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                        onClick={() => {
                          // @ts-ignore
                          acceptOperator(job._id);
                          setIsOpen(false);
                        }}
                        ref={cancelButtonRef}
                      >
                        Accept
                      </button>

                      <button
                        type="button"
                        className="mt-3 mr-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                        onClick={() => {
                          setIsOpen(false);
                        }}
                        ref={cancelButtonRef}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
