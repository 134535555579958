export interface Location {
  type: "Point";
  coordinates: [number, number];
}

export interface IndependentOperatorDetail {
  hourlyRate: number;
  skills: string[];
  onCall: boolean;
  startTime: string;
  endTime: string;
  location: Location;
}

export enum UserType {
  MANAGER = "MANAGER",
  INDEPENDENT_OPERATOR = "INDEPENDENT_OPERATOR",
}

export interface Operator {
  _id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  createTimestamp: Date;
  updateTimestamp: Date;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  active: boolean;
  userType: UserType.INDEPENDENT_OPERATOR;
  detail: IndependentOperatorDetail;
}
